import { Mention, MentionsInput } from "react-mentions";
import Icon from "../../../Icon";
import React, { useRef, useCallback, useEffect } from "react";
import { Node } from "reactflow";

const SingleLineStyle = {
  control: {
    backgroundColor: "",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "20px",
    fontFamily: "inherit",
    boxSizing: "border-box",
  },

  "&singleLine": {
    display: "inline-block",
    width: "100%",
    highlighter: {
      padding: 8,
      border: "1px solid transparent",
      lineHeight: "20px",
    },
    input: {
      padding: 8,
      border: "none",
      outline: "none",
      lineHeight: "20px",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid #D1D5DB",
      borderRadius: "6px",
      fontSize: 14,
    },
    item: {
      padding: "8px 12px",
      color: "#374151",
      "&focused": {
        backgroundColor: "#EFF6FF",
        outline: "none",
        border: "none",
      },
    },
  },
};

interface APISingleLineInputProps {
  teamSecrets: string[];
  userSecrets: string[];
  variables: string[];
  outputs: Node[];
  value: string;
  updated: (text: string) => void;
  placeholder?: string;
}

export const APISingleLineInput: React.FC<APISingleLineInputProps> = ({
  teamSecrets,
  userSecrets,
  variables,
  outputs,
  value,
  updated,
  placeholder,
}) => {
  const mentionsInputRef = useRef<HTMLTextAreaElement>(null);
  const cursorPositionRef = useRef<number | null>(null);
  const isUpdatingRef = useRef(false);

  const handleTextChange = useCallback(
    (_: { target: { value: string } }, newValue: string) => {
      if (mentionsInputRef.current) {
        cursorPositionRef.current = mentionsInputRef.current.selectionStart;
        isUpdatingRef.current = true;
      }
      updated(newValue);
    },
    [updated]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        mentionsInputRef.current &&
        cursorPositionRef.current !== null &&
        isUpdatingRef.current
      ) {
        mentionsInputRef.current.setSelectionRange(
          cursorPositionRef.current,
          cursorPositionRef.current
        );
        isUpdatingRef.current = false;
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  });

  const handleMentionAdd = useCallback((id: string | number) => {
    if (cursorPositionRef.current !== null) {
      const idLength = id.toString().length;
      cursorPositionRef.current += idLength + 2;
    }
  }, []);

  return (
    <div className="max-w-full">
      <MentionsInput
        id="headerInput"
        value={value}
        onChange={handleTextChange}
        placeholder={placeholder}
        inputRef={mentionsInputRef}
        singleLine
        style={SingleLineStyle}
        className="border-gray-200 border rounded-lg bg-gray-50"
      >
        <Mention
          trigger="@"
          style={{ backgroundColor: "#FFE3B0", borderRadius: 4 }}
          appendSpaceOnAdd={true}
          data={userSecrets.map((secret) => ({
            id: `userSecrets.${secret}`,
            display: `userSecrets.${secret}`,
          }))}
          onAdd={handleMentionAdd}
          renderSuggestion={(data) => (
            <div className="flex items-center gap-2">
              <Icon type="users" className="size-3 text-orange-500" />
              <span>{data.display}</span>
            </div>
          )}
        />
        <Mention
          trigger="@"
          style={{ backgroundColor: "#E3EBB0", borderRadius: 4 }}
          appendSpaceOnAdd={true}
          data={teamSecrets.map((secret) => ({
            id: `teamSecrets.${secret}`,
            display: `teamSecrets.${secret}`,
          }))}
          onAdd={handleMentionAdd}
          renderSuggestion={(data) => (
            <div className="flex items-center gap-2">
              <Icon type="api" className="size-3 text-blue-500" />
              <span>{data.display}</span>
            </div>
          )}
        />
        <Mention
          trigger="@"
          style={{ backgroundColor: "#B0E3FF", borderRadius: 4 }}
          appendSpaceOnAdd={true}
          data={variables.map((variable) => ({
            id: `variables.${variable}`,
            display: `variables.${variable}`,
          }))}
          onAdd={handleMentionAdd}
          renderSuggestion={(data) => (
            <div className="flex items-center gap-2">
              <Icon type="users" className="size-3 text-sky-500" />
              <span>{data.display}</span>
            </div>
          )}
        />
        <Mention
          trigger="@"
          style={{ backgroundColor: "#E3EBB0", borderRadius: 4 }}
          appendSpaceOnAdd={true}
          data={outputs.map((output) => ({
            id: `outputs.${output.data.id}`,
            display: `outputs.${output.data.title}`,
          }))}
          onAdd={handleMentionAdd}
          renderSuggestion={(data) => (
            <div className="flex items-center gap-2">
              <Icon type="arrow-left" className="size-3 text-blue-500" />
              <span>{data.display}</span>
            </div>
          )}
        />
      </MentionsInput>
    </div>
  );
};
