import { Repository } from "../repos/Repository";
import { PortalLog } from "../models/PortalLog";
import { WorkflowLog } from "../models/WorkflowLog";

export interface PortalLogService {
  portalLogRepo: Repository<PortalLog>;
  portalLogPath(teamId: string): string;
  workflowLogRepo: Repository<WorkflowLog>;
  workflowLogPath(teamId: string): string;
}

export class FirestorePortalLogService implements PortalLogService {
  constructor(
    public portalLogRepo: Repository<PortalLog>,
    public workflowLogRepo: Repository<WorkflowLog>
  ) {}
  portalLogPath(teamId: string): string {
    return `teams/${teamId}/logs`;
  }
  workflowLogPath(teamId: string): string {
    return `teams/${teamId}/workflowLogs`;
  }
}
