import React from "react";
import Icon from "../Icon";

interface PaginationProps {
  currentPage: number;
  hasMore: boolean;
  onPageChange: (direction: "prev" | "next") => void;
}

const CommonPagination: React.FC<PaginationProps> = ({
  currentPage,
  hasMore,
  onPageChange,
}) => {
  return (
    <div className="h-8 justify-center items-center inline-flex">
      <div
        className={`self-stretch p-1 rounded-l ${
          currentPage > 1 ? "hover:bg-gray-50 cursor-pointer" : "opacity-50"
        } bg-white border-t border-l border-b border-gray-300 flex-col justify-center items-center inline-flex`}
        onClick={() => currentPage > 1 && onPageChange("prev")}
      >
        <Icon type="chevron" className="-rotate-90 text-gray-500" />
      </div>
      <div className="self-stretch px-3 py-1.5 bg-gray-100 border border-gray-300 flex-col justify-center items-center inline-flex">
        <div className="text-center text-sm font-medium leading-[21px] text-[#111928]">
          {currentPage}
        </div>
      </div>
      <div
        className={`self-stretch p-1 rounded-r ${
          hasMore ? "hover:bg-gray-50 cursor-pointer" : "opacity-50"
        } bg-white border-t border-r border-b border-gray-300 flex-col justify-center items-center inline-flex`}
        onClick={() => hasMore && onPageChange("next")}
      >
        <Icon type="chevron" className="rotate-90 text-gray-500" />
      </div>
    </div>
  );
};

export default CommonPagination;
