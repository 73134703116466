import React, { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import FailureModal from "../FailureModal";
import { usePortalService } from "../../contexts/PortalContext";
import AnalyticsChart, { ChartType } from "./AnalyticsChart";
import { useTeams } from "../../contexts/TeamContext";
import { CommonSpinner } from "../Common/CommonLoading";
import { Team } from "../../models/Team";
import { InputData } from "../TestCenter/TestRunDetailModal";
import { CommonWorkflowPortalSelector } from "../Common/CommonWorkflowPortalSelector";

export type Timespan = "7-Days" | "30-Days" | "60-Days";

export interface AnalyticsData {
  [versionId: string]: { stats: VersionStats | undefined; date: Date }[];
}

export const Analytics: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const [searchParams] = useSearchParams();
  const teamService = useTeams();
  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState("");
  const portalService = usePortalService();

  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>();
  const [loadingData, setLoadingData] = useState(false);

  const [durationType, setDurationType] = useState<ChartType>("line");
  const [scoreType, setScoreType] = useState<ChartType>("line");
  const [errorRateType, setErrorRateType] = useState<ChartType>("line");
  const [costType, setCostType] = useState<ChartType>("line");
  const [latencyType, setLatencyType] = useState<ChartType>("line");
  const [requestCountType, setRequestCountType] = useState<ChartType>("line");

  // Load team data
  useEffect(() => {
    const loadTeam = async () => {
      if (!teamId) return;
      const fetchedTeam = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId
      );
      setTeam(fetchedTeam ?? undefined);
    };

    loadTeam();
  }, [teamId, teamService]);

  // Derive InputData from URL params for analytics data fetching
  const inputData = useMemo<InputData | undefined>(() => {
    const type = searchParams.get("type") as "portal" | "workflow" | null;
    const id = searchParams.get("id");
    const versionIds = searchParams
      .get("versionIds")
      ?.split(",")
      .filter(Boolean);
    setAnalyticsData(undefined);

    if (!type || !id) return undefined;

    return type === "portal"
      ? { type: "portal", itemId: id, versionIds }
      : { type: "workflow", itemId: id, versionIds };
  }, [searchParams]);

  // Fetch analytics data
  useEffect(() => {
    const fetchAnalyticsData = async () => {
      if (!inputData?.versionIds?.length || loadingData || analyticsData) {
        return;
      }

      setLoadingData(true);
      try {
        const timespan = (searchParams.get("timespan") as Timespan) || "7-Days";
        const daysAgo = Number(timespan.split("-")[0]);
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - daysAgo);

        const id =
          inputData.type == "portal"
            ? portalService.portalStatsPath(teamId!, inputData.itemId)
            : portalService.workflowStatsPath(teamId!, inputData.itemId);

        const stats = await portalService.portalStatsRepo.getList(
          id,
          { name: "date", descending: true },
          [{ key: "date", filter: ">", value: startDate }]
        );

        const newAnalyticsData: AnalyticsData = inputData.versionIds.reduce(
          (acc, versionId) => {
            acc[versionId] = stats.map((s) => {
              return { stats: s.versionStats[versionId], date: s.date };
            });
            return acc;
          },
          {} as AnalyticsData
        );

        setAnalyticsData(newAnalyticsData);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      } finally {
        setLoadingData(false);
      }
    };

    fetchAnalyticsData();
  }, [inputData, teamId, searchParams.get("timespan")]);

  return (
    <CommonContainer>
      <CommonHeader
        title="Analytics"
        subtitle="Insights on your portals and workflows based on their logs & reviews. The best version is out there somewhere."
        sections={[{ name: "Analytics", link: `/teams/${teamId}/analytics` }]}
        teamId={teamId!}
        actions={[]}
      />

      <FailureModal
        shows={error !== ""}
        message={error}
        closed={() => setError("")}
      />

      {team && <CommonWorkflowPortalSelector showTimespan={true} team={team} />}

      {!inputData && (
        <div className="font-gooper text-lg text-gray-700">
          Select a portal or workflow to get started
        </div>
      )}

      {inputData && (
        <div>
          {loadingData && <CommonSpinner />}
          {!loadingData && analyticsData && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4">
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={inputData.versionIds || []}
                metric="requestCount"
                timespan={
                  (searchParams.get("timespan") as Timespan) || "7-Days"
                }
                type={requestCountType}
                setType={setRequestCountType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={inputData.versionIds || []}
                metric="score"
                timespan={
                  (searchParams.get("timespan") as Timespan) || "7-Days"
                }
                type={scoreType}
                setType={setScoreType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={inputData.versionIds || []}
                metric="latency"
                timespan={
                  (searchParams.get("timespan") as Timespan) || "7-Days"
                }
                type={latencyType}
                setType={setLatencyType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={inputData.versionIds || []}
                metric="duration"
                timespan={
                  (searchParams.get("timespan") as Timespan) || "7-Days"
                }
                type={durationType}
                setType={setDurationType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={inputData.versionIds || []}
                metric="cost"
                timespan={
                  (searchParams.get("timespan") as Timespan) || "7-Days"
                }
                type={costType}
                setType={setCostType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={inputData.versionIds || []}
                metric="errors"
                timespan={
                  (searchParams.get("timespan") as Timespan) || "7-Days"
                }
                type={errorRateType}
                setType={setErrorRateType}
              />
            </div>
          )}
        </div>
      )}
    </CommonContainer>
  );
};
