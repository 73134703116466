import { FC, useState, useEffect } from "react";
import { Team } from "../../../../models/Team";
import { APINodeData } from "../../Map/Nodes/APINode";
import Icon from "../../../Icon";
import { CommonModal } from "../../../Common/CommonModal";
import { VariableInput } from "../../../Portals/Portal Detail/VersionDemor";
import { useTestService } from "../../../../contexts/TestContext";
import AnimatedButton, { AnimationState } from "../../../AnimatedButton";

interface APITestModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  data: APINodeData;
  team: Team;
}

interface VariableMap {
  [key: string]: string;
}

interface VariableMention {
  display: string;
  id: string;
}

export const APITestModal: FC<APITestModalProps> = ({
  shows,
  data,
  setShows,
  team,
}) => {
  const [response, setResponse] = useState<any>(null);
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const [error, setError] = useState<string | null>(null);
  const [variables, setVariables] = useState<VariableMap>({});
  const [userSecrets, setUserSecrets] = useState<VariableMap>({});
  const [outputs, setOutputs] = useState<VariableMap>({});

  const testService = useTestService();

  const extractMentions = (text: string) => {
    const mentions = {
      variables: new Set<VariableMention>(),
      userSecrets: new Set<VariableMention>(),
      outputs: new Set<VariableMention>(),
    };

    // Match @[type.display](type.id) pattern
    const mentionRegex =
      /@\[(?:(variables|userSecrets|outputs))\.([^\]]+)\]\((?:\1)\.([^)]+)\)/g;
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      const [_, type, display, id] = match;
      mentions[type as keyof typeof mentions].add({ display, id });
    }

    // Also match simple variables.key and userSecrets.key patterns
    const simpleRegex = /(variables|userSecrets)\.([\w]+)/g;
    while ((match = simpleRegex.exec(text)) !== null) {
      const [_, type, key] = match;
      if (!text.includes(`@[${type}.${key}]`)) {
        // Avoid duplicates with mention format
        mentions[type as "variables" | "userSecrets"].add({
          display: key,
          id: key,
        });
      }
    }

    return mentions;
  };

  useEffect(() => {
    if (shows) {
      const textToSearch = [
        data.url,
        JSON.stringify(data.headers),
        data.body || "",
      ].join(" ");

      const mentions = extractMentions(textToSearch);

      setVariables(
        Object.fromEntries([...mentions.variables].map((v) => [v.display, ""]))
      );
      setUserSecrets(
        Object.fromEntries(
          [...mentions.userSecrets].map((v) => [v.display, ""])
        )
      );
      setOutputs(
        Object.fromEntries([...mentions.outputs].map((v) => [v.display, ""]))
      );

      console.log("MENTIONS", mentions);
    }
  }, [shows, data]);

  const renderVariableSection = (
    title: string,
    values: VariableMap,
    setValues: (values: VariableMap) => void
  ) => {
    if (Object.keys(values).length === 0) return null;

    return (
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">{title}</h3>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(values).map(([key, value]) => (
            <VariableInput
              key={key}
              title={key}
              value={value}
              setNewValue={(newValue) => {
                setValues({
                  ...values,
                  [key]: newValue,
                });
              }}
              className="w-full"
            />
          ))}
        </div>
      </div>
    );
  };

  const testAPI = async () => {
    setButtonState("loading");
    setError(null);
    setResponse(null);

    try {
      const response = await testService.runWorkflowAPITest(
        team.id!,
        data.url,
        data.headers || {},
        outputs,
        userSecrets,
        variables,
        data.method,
        data.body
      );

      try {
        setResponse(JSON.parse(response));
        setButtonState("success");
      } catch {
        setResponse(response);
        setButtonState("success");
      }
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to create test. Please try again."
      );
      setButtonState("error");
    }
  };

  return (
    <CommonModal isOpen={shows} onDismiss={() => setShows(false)}>
      <div className="w-full max-w-5xl h-[90vh] flex flex-col bg-white rounded-lg overflow-hidden">
        <div className="flex-none px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-medium">{`Demo: ${data.title}`}</h2>
            <button
              onClick={() => setShows(false)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <Icon type="close" />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto px-6 py-4">
          <div className="grid grid-cols-1 gap-6 mb-6">
            {renderVariableSection("Variables", variables, setVariables)}
            {renderVariableSection("User Secrets", userSecrets, setUserSecrets)}
            {renderVariableSection(
              "Previous Step Outputs",
              outputs,
              setOutputs
            )}
          </div>

          <div className="space-y-4">
            <h3 className="font-medium mb-2">Request Details</h3>
            <div className="grid grid-cols-1 gap-4">
              <div className="bg-gray-50 p-4 rounded-md border border-gray-200">
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div>
                    <p className="text-sm text-gray-600">Method</p>
                    <p className="font-mono">{data.method}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">URL</p>
                    <p className="font-mono break-all">{data.url}</p>
                  </div>
                </div>

                {Object.keys(data.headers || {}).length > 0 && (
                  <div>
                    <p className="text-sm text-gray-600 mb-2">Headers</p>
                    <pre className="font-mono text-sm bg-white p-2 rounded border border-gray-200 max-h-[200px] overflow-auto">
                      {JSON.stringify(data.headers, null, 2)}
                    </pre>
                  </div>
                )}

                {data.body && (
                  <div className="mt-4">
                    <p className="text-sm text-gray-600 mb-2">Request Body</p>
                    <pre className="font-mono text-sm bg-white p-2 rounded border border-gray-200 max-h-[200px] overflow-auto">
                      {JSON.stringify(JSON.parse(data.body), null, 2)}
                    </pre>
                  </div>
                )}
              </div>
            </div>
          </div>

          {error && (
            <div className="p-4 bg-red-50 text-red-500 rounded-md border border-red-200 mt-4">
              <p className="font-medium">Error</p>
              <p className="mt-1">{error}</p>
            </div>
          )}

          {response && (
            <div className="flex flex-col gap-2 mt-4">
              <p className="font-medium">Response:</p>
              <pre className="p-4 bg-gray-50 rounded-md overflow-auto max-h-[600px] border border-gray-200 font-mono text-sm">
                {JSON.stringify(response, null, 2)}
              </pre>
            </div>
          )}
        </div>

        <div className="flex-none px-6 py-4 border-t border-gray-200">
          <AnimatedButton
            title="Test API"
            onClick={testAPI}
            buttonState={buttonState}
            setButtonState={setButtonState}
            style="action"
            font="font-sans"
            classNameIn="w-full"
            after={(success) => {
              if (!success) {
                setButtonState("ready");
              }
            }}
          />
        </div>
      </div>
    </CommonModal>
  );
};
