import { Portal } from "../models/Portal";
import { PortalLog } from "../models/PortalLog";
import { Team } from "../models/Team";
import { Workflow } from "../models/Workflow";
import { WorkflowLog } from "../models/WorkflowLog";

export const filterExamplePortals = (
  team: Team | undefined,
  portal: Portal
): boolean => {
  if (team?.hideExamples == undefined) {
    return true;
  }
  if (team.hideExamples == true) {
    return !portal.isExample;
  } else {
    return true;
  }
};

export const filterExampleWorkflows = (
  team: Team | undefined,
  workflow: Workflow
): boolean => {
  if (team?.hideExamples == undefined) {
    return true;
  }
  if (team.hideExamples == true) {
    return !workflow.isExample;
  } else {
    return true;
  }
};

export const filterExampleLogs = (
  team: Team | undefined,
  log: PortalLog | WorkflowLog
): boolean => {
  if (team?.hideExamples == undefined) {
    return true;
  }
  if (team.hideExamples == true) {
    return !log.isExample;
  } else {
    return true;
  }
};
