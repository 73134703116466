import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import OnboardingPage from "./OnboardingPage";
import SelectionBoard from "./SelectionBoard";
import { OnboardingStep } from "./UserOnboarding";
import { NextButton } from "./NextButton";

const sizes = [
  "I’m totally new to Ai",
  "I’ve used tools like ChatGPT, but only to chat with",
  "I’ve used ChatGPT’s API or done multi-step prompting",
  "I’m a master, and have trained models or integrated RAG systems",
];

const UserAiExp: React.FC<{
  setStep: (step: OnboardingStep) => void;
  skipSurvey: () => void;
  progress: number;
}> = ({ setStep, skipSurvey, progress }) => {
  const authService = useAuth();

  const [error, setError] = useState("");
  const [selectedExp, setSelectedExp] = useState<string>();

  const handleSubmit = async () => {
    try {
      const currentUser = await authService.currentUser();
      if (!currentUser) return;

      await authService.userRepo.update(
        { aiExp: selectedExp ?? "SKIPPED" },
        authService.userPath(),
        currentUser.id
      );
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
    setStep(OnboardingStep.Referral);
  };

  return (
    <OnboardingPage
      error={error}
      title={"Question 3/4"}
      headline="How familiar are you with Ai?"
      message="Our goal is to bring Ai to everyone, no matter your experience."
      isLoading={false}
      progress={progress}
      setError={() => undefined}
      didSkip={skipSurvey}
    >
      <SelectionBoard
        items={sizes}
        selected={selectedExp}
        setSelected={setSelectedExp}
        columns={1}
        textSize="text-base"
        animate={true}
      />
      <NextButton onClick={handleSubmit} />
    </OnboardingPage>
  );
};

export default UserAiExp;
