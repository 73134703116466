import React, { useEffect, useRef, useState } from "react";
import { TestRun } from "../../models/TestRun";
import CommonPagination from "./Pagination";
import { CommonSpinner } from "../Common/CommonLoading";
import { StatusBadge } from "./StatusBadge";
import { Team } from "../../models/Team";
import { InputData, TestRunDetailModal } from "./TestRunDetailModal";
import { useTestService } from "../../contexts/TestContext";
import { WorkflowTestRun } from "../../models/WorkflowTest";

type TestRunType = TestRun | WorkflowTestRun;

export const TestRunTable: React.FC<{
  team: Team;
  data: InputData | undefined;
}> = ({ team, data }) => {
  const testService = useTestService();

  const [testRuns, setTestRuns] = useState<TestRunType[]>();
  const [currentTestRunPage, setCurrentTestRunPage] = useState(1);
  const [testRunPageDates, setTestRunPageDates] = useState<Date[]>([]);
  const [loading, setLoading] = useState(false);

  const [testRunId, setTestRunId] = useState<string>();

  const unsubRef = useRef<() => void>();

  const pageLimit = 10;

  useEffect(() => {
    const fetchTestRuns = async () => {
      if (
        !data ||
        (data.versionIds?.length ?? 0) === 0 ||
        testRuns !== undefined
      ) {
        return;
      }

      setLoading(true);
      try {
        const startAfterDate =
          currentTestRunPage === 1
            ? undefined
            : testRunPageDates[currentTestRunPage - 1];

        unsubRef.current?.();

        if (data.type === "portal") {
          unsubRef.current = testService.testRunRepo.observeList(
            testService.testRunPath(team.id!, data.itemId),
            async (runs) => {
              setTestRuns(runs);
              if (runs.length > 0) {
                const lastRunDate = runs[runs.length - 1].createdAt!;
                setTestRunPageDates((prevDates) => {
                  const newDates = [...prevDates];
                  newDates[currentTestRunPage] = lastRunDate;
                  return newDates;
                });
              }
            },
            { name: "createdAt", descending: true },
            pageLimit,
            startAfterDate
          );
        } else if (data.type === "workflow") {
          unsubRef.current = testService.workflowTestRunRepo.observeList(
            testService.workflowTestRunPath(team.id!, data.itemId),
            async (runs) => {
              setTestRuns(runs);
              if (runs.length > 0) {
                const lastRunDate = runs[runs.length - 1].createdAt!;
                setTestRunPageDates((prevDates) => {
                  const newDates = [...prevDates];
                  newDates[currentTestRunPage] = lastRunDate;
                  return newDates;
                });
              }
            },
            { name: "createdAt", descending: true },
            pageLimit,
            startAfterDate
          );
        }

        setLoading(false);
      } catch (e) {
        setTestRuns([]);
        setLoading(false);
      }
    };

    fetchTestRuns();
  }, [data, testService, currentTestRunPage, testRunPageDates, team.id]);

  useEffect(() => {
    setTestRuns(undefined);
    setCurrentTestRunPage(1);
    setTestRunPageDates([]);
  }, [data]);

  const handleTestRunClick = (testRun: TestRunType) => {
    setTestRunId(testRun.id);
  };

  return (
    <div className="flex flex-col gap-6">
      {testRunId && data && (
        <TestRunDetailModal
          testRunId={testRunId}
          teamId={team.id!}
          data={data}
          closed={() => {
            setTestRunId(undefined);
          }}
        />
      )}

      <div className="flex flex-col gap-2">
        <div className="font-gooper text-xl text-gray-700">Test Results</div>
        <div className="text-sm text-gray-500">
          Results stream in live. Click to see more information.
        </div>
      </div>
      {loading || data == undefined ? (
        <div className="flex items-center justify-center">
          <CommonSpinner />
        </div>
      ) : (
        <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
          <tbody>
            {testRuns?.map((testRun) => {
              return (
                <tr className="bg-gray-0 " key={testRun.id}>
                  <td className="border border-gray-200">
                    <div className="p-3 flex">
                      <div className="text-sm text-gray-700 font-medium flex lg:w-1/2 w-1/3 truncate">
                        {testRun.testName}
                      </div>
                      <div className="grid grid-cols-3 w-2/3 lg:w-1/2">
                        <div className="text-xs text-gray-500 flex items-center truncate">
                          {testRun.versionName}
                        </div>
                        <div className="flex justify-center">
                          <StatusBadge status={testRun.status} />
                        </div>
                        <button
                          onClick={() => handleTestRunClick(testRun)}
                          className="font-gooper text-xs text-blue-500 hover:text-blue-700 font-medium flex justify-end items-center"
                        >
                          Results
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {testRuns?.length == 0 && !loading && (
        <div className="text-gray-700 font-gooper">
          Run a test & see the results here.
        </div>
      )}

      <CommonPagination
        currentPage={currentTestRunPage}
        hasMore={(testRuns?.length ?? 0) > pageLimit}
        onPageChange={(direction) => {
          setTestRuns(undefined);
          if (direction === "next") {
            setCurrentTestRunPage((prev) => prev + 1);
          } else if (direction === "prev" && currentTestRunPage > 1) {
            setCurrentTestRunPage((prev) => prev - 1);
          }
        }}
      />
    </div>
  );
};
