import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon, { IconType } from "../Icon";
import { CommonSpinner } from "../Common/CommonLoading";

interface NavigationLinkProps {
  title: string;
  icon: IconType;
  destination: string;
  isActive: boolean;
  teamId: string;
  isCollapsed: boolean;
  actionLink?: string;
  id?: string;
  action?: () => Promise<void>;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  title,
  destination,
  icon,
  isActive,
  teamId,
  isCollapsed,
  actionLink,
  id,
  action,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleActionClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (actionLink) {
      navigate(actionLink);
    } else if (action) {
      setLoading(true);
      try {
        await action();
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMainClick = (event: React.MouseEvent) => {
    if (event.button === 0) {
      event.preventDefault();
      navigate(`/teams/${teamId}/${destination}`);
    }
  };

  const url = `/teams/${teamId}/${destination}`;

  return (
    <a
      href={url}
      className={`flex items-center ${
        isCollapsed ? "justify-center" : "justify-between"
      } gap-2 py-2 px-1 hover:bg-blue-50 rounded-lg font-gooper ${
        isActive ? "text-blue-500" : "text-gray-800"
      }`}
      onClick={handleMainClick}
      id={id}
    >
      <div className="flex relative items-center gap-2">
        <Icon
          type={icon}
          className={`size-5 ${
            isCollapsed && isActive ? "text-blue-400" : "text-gray-300"
          }`}
        />
        {!isCollapsed && (
          <span className="text-base font-medium leading-normal">{title}</span>
        )}
      </div>
      {loading && <CommonSpinner size="sm" />}
      {(actionLink || action) && !isCollapsed && !loading && (
        <button
          onClick={handleActionClick}
          className="ml-auto relative z-10"
          type="button"
        >
          <Icon type="plus-inverted" className="text-blue-500 size-4" />
        </button>
      )}
    </a>
  );
};

export default NavigationLink;
