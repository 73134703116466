import { useState } from "react";
import Icon from "../../../Icon";

export const UserSecretsInput: React.FC<{
  userSecrets?: { [key: string]: boolean };
  addUserSecret: (secret: string) => void;
  removeUserSecret: (secret: string) => void;
}> = ({ userSecrets = {}, addUserSecret, removeUserSecret }) => {
  const [newSecret, setNewSecret] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const secrets = Object.keys(userSecrets);

  const handleNewSecret = () => {
    if (newSecret.trim()) {
      addUserSecret(newSecret.trim());
      setNewSecret("");
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="text-sm text-gray-700 font-medium">User Secrets</div>
      <div className="text-xs text-gray-400">
        These secrets will be required from users when running the workflow.
      </div>
      <div className="relative">
        <input
          className={`border-gray-300 w-full focus:border-blue-500 bg-gray-50 text-gray-700 border rounded-md p-2 leading-tight text-sm focus:ring-0 focus:outline-none h-10`}
          value={newSecret}
          onChange={(e) => setNewSecret(e.target.value)}
          type="text"
          placeholder="Add required user secrets"
          id="userSecretInput"
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setTimeout(() => setIsFocused(false), 100);
          }}
          autoComplete="off"
        />
        {isFocused && newSecret != "" && (
          <div className="absolute w-full text-left flex flex-col items-start bg-gray-0 border border-gray-300 rounded-md mt-1 z-50">
            <button
              onClick={handleNewSecret}
              className="cursor-pointer flex flex-row gap-2 w-full py-3 items-center px-4"
            >
              <Icon
                type="users"
                className="text-blue-500 fill-blue-500 size-3"
              />
              <div className="text-sm text-blue-500">{`Add ${newSecret} as required secret`}</div>
            </button>
          </div>
        )}
        <div className="flex flex-wrap gap-1 mt-2">
          {secrets.map((secret) => (
            <div
              key={secret}
              className="h-6 px-1.5 py-0.5 bg-gray-100 rounded justify-start items-center gap-1 inline-flex"
            >
              <div className="text-center text-gray-500 text-xs font-medium font-['General Sans'] leading-[18px]">
                {secret}
              </div>
              <button
                onClick={() => removeUserSecret(secret)}
                className="w-2.5 h-2.5 relative"
              >
                <Icon
                  type="x-no-circle"
                  className="text-gray-500 fill-gray-500 size-3"
                />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
