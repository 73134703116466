import React from "react";
import { useReactFlow, Position } from "reactflow";
import Icon from "../../../Icon";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";
import { createSelectorNode } from "./SelectorNode";
import { PortalConfig } from "../../../../models/PortalVersion";
import { AIProviderLogo } from "../../../Portals/Portal Detail/AIProvider/AiProviderLogo";

export const ActionNodeType = "workflowActionNodeType";

export interface Action {
  id: string;
  name: string;
  description: string;
}

export interface ActionNodeData {
  id: string;
  teamId: string;
  type: typeof ActionNodeType;
  title: string;
  actions: Action[];
  providerId: string;
  providerConfig: PortalConfig;
}

export interface ActionNodeParams {
  data: ActionNodeData;
}

export const ActionNode: React.FC<ActionNodeParams> = ({ data }) => {
  const { getNode, getEdges, setNodes } = useReactFlow();

  const actionHandleId = (actionId: string, index: number): string => {
    return `${data.id}_ACTION:${actionId}_INDEX:${index}_source`;
  };

  const sourceConnected = (id: string) => {
    const edges = getEdges();
    return edges.some((edge) => {
      return edge.sourceHandle === id;
    });
  };

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === data.id);
  };

  const selected = getNode(data.id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    selected ? "border-blue-500" : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56 transition-all`;

  const handleAdd = (source: string) => {
    const currentNode = getNode(data.id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(
      currentNode,
      data.teamId,
      undefined,
      undefined,
      source
    );
    setNodes((nds) => nds.concat(selectionNode));
  };

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${data.id}_target`}
      />
      <div className="flex flex-row justify-between h-6">
        <ActionBadge />
        <AIProviderLogo
          providerId={data.providerId}
          className="fill-gray-500 bg-gray-100 rounded-lg p-1 h-6 w-12"
        />
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="flex flex-col gap-2">
        {data.actions.map((action, index) => (
          <div
            key={action.id}
            className="relative flex flex-col gap-1 p-2 rounded-md bg-gray-50 group"
          >
            <div className="text-sm text-gray-700 font-medium truncate">
              {action.name}
            </div>
            <div className="text-xs text-gray-500 truncate">
              {action.description}
            </div>
            <div className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-full">
              <AddNodeHandler
                type="source"
                isConnected={sourceConnected(actionHandleId(action.id, index))}
                onClick={() => handleAdd(actionHandleId(action.id, index))}
                id={actionHandleId(action.id, index)}
                position={Position.Right}
              />
            </div>
          </div>
        ))}
      </div>
      <AddNodeHandler
        onClick={() => handleAdd(`${data.id}_source`)}
        id={`${data.id}_source`}
        type="source"
        isConnected={sourceConnected(`${data.id}_source`)}
      />
    </div>
  );
};

export const ActionBadge: React.FC = () => {
  return (
    <div className="px-2.5 py-0.5 rounded justify-center items-center gap-1 flex bg-purple-50 text-purple-400">
      <Icon type="tools" className="size-3" />
      <div className="text-center text-xs font-medium leading-[18px]">
        Actions
      </div>
    </div>
  );
};

// export const createActionNode = (
//   position: { x: number; y: number },
//   teamId: string
// ): {
//   id: string;
//   type: string;
//   position: { x: number; y: number };
//   data: ActionNodeData;
// } => {
//   const id = `action_${Date.now()}`;
//   return {
//     id,
//     type: ActionNodeType,
//     position,
//     data: {
//       id,
//       teamId,
//       type: ActionNodeType,
//       title: "Evaluate Actions",
//       actions: [],
//       provider: "openai",
//     },
//   };
// };
