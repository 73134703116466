import React, { useEffect, useState } from "react";
import UserAddName from "./UserAddName";
import UserCheckInvite from "./UserCheckInvite";
import { useAuth } from "../../contexts/AuthContext";
import FailureModal from "../FailureModal";
import UserRole from "./UserRole";
import { User } from "../../models/User";
import UserOrgSize from "./UserOrgSize";
import UserAiExp from "./UserAiExp";
import UserRefferal from "./UserReferral";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { useUserEvent } from "../../contexts/UserEventContext";
import { useNavigate } from "react-router-dom";
import { CommonLoading } from "../Common/CommonLoading";
import { isProd } from "../../utils/EnvironmentUtil";

export enum OnboardingStep {
  AddName,
  CheckInvite,
  Role,
  OrgSize,
  AiExp,
  Referral,
}

const UserOnboarding: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(OnboardingStep.CheckInvite);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const auth = useAuth();
  const events = useUserEvent();

  const skipSurvey = async () => {
    setIsLoading(true);
    try {
      const currentUser = await auth.currentUser();
      if (!currentUser) return;
      const updates: Partial<User> = {};
      if (!currentUser.role) {
        updates.role = "SKIPPED";
      }
      if (!currentUser.orgSize) {
        updates.orgSize = "SKIPPED";
      }
      if (!currentUser.aiExp) {
        updates.aiExp = "SKIPPED";
      }
      if (!currentUser.referral) {
        updates.referral = "SKIPPED";
      }
      await events.createEvent(auth, "OnboardingEnd");
      await auth.userRepo.update(updates, auth.userPath(), currentUser.id);
      setTimeout(() => {
        navigate("/postonboarding");
      }, 2000);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    auth
      .currentUser()
      .then((user) => {
        if (!user) {
          setError("no user");
          return;
        }
        if (!user.inviteCode && !isProd) {
          setCurrentStep(OnboardingStep.CheckInvite);
        } else if (!user.name) {
          setCurrentStep(OnboardingStep.AddName);
        } else if (!user.role) {
          setCurrentStep(OnboardingStep.Role);
        } else if (!user.orgSize) {
          setCurrentStep(OnboardingStep.OrgSize);
        } else if (!user.aiExp) {
          setCurrentStep(OnboardingStep.AiExp);
        } else if (!user.referral) {
          setCurrentStep(OnboardingStep.Referral);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e instanceof Error) {
          setError(e.message);
        }
      });
  }, [auth]);

  const calculateProgress = (step: OnboardingStep): number => {
    const modifier = localStorage.getItem(STORAGE_KEYS.INVITED_TEAM_ID)
      ? 1
      : 0.5;
    switch (step) {
      case OnboardingStep.AddName:
        return modifier * 17;
      case OnboardingStep.Role:
        return modifier * 33;
      case OnboardingStep.OrgSize:
        return modifier * 50;
      case OnboardingStep.AiExp:
        return modifier * 67;
      case OnboardingStep.Referral:
        return modifier * 84;
      default:
        return 0;
    }
  };

  const renderStep = () => {
    const progress = calculateProgress(currentStep);
    switch (currentStep) {
      case OnboardingStep.CheckInvite:
        return <UserCheckInvite setStep={setCurrentStep} />;
      case OnboardingStep.AddName:
        return <UserAddName setStep={setCurrentStep} progress={progress} />;
      case OnboardingStep.Role:
        return (
          <UserRole
            setStep={setCurrentStep}
            skipSurvey={skipSurvey}
            progress={progress}
          />
        );
      case OnboardingStep.OrgSize:
        return (
          <UserOrgSize
            setStep={setCurrentStep}
            skipSurvey={skipSurvey}
            progress={progress}
          />
        );
      case OnboardingStep.AiExp:
        return (
          <UserAiExp
            setStep={setCurrentStep}
            skipSurvey={skipSurvey}
            progress={progress}
          />
        );
      case OnboardingStep.Referral:
        return <UserRefferal skipSurvey={skipSurvey} progress={progress} />;
      default:
        return null;
    }
  };

  return (
    <>
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to onboarding"}
        shows={error != ""}
        closed={() => setError("")}
      />
      {isLoading && <CommonLoading />}
      {renderStep()}
    </>
  );
};

export default UserOnboarding;
