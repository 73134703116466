import React, { useState, useEffect } from "react";
import { PortalLog } from "../../models/PortalLog";
import { WorkflowLog } from "../../models/WorkflowLog";
import { usePortalLogService } from "../../contexts/PortalLogContext";
import CommonHeader from "../Common/CommonHeader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import FailureModal from "../FailureModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { LogModal } from "./LogModal";
import { WorkflowLogModal } from "./WorkflowLogModal";
import { useTeams } from "../../contexts/TeamContext";
import { CommonNoData } from "../Common/CommonNoData";
import { filterExampleLogs } from "../../utils/FilterUtils";
import { CommonWorkflowPortalSwitch } from "../Common/CommonWorkflowPortalSwitch";
import { PortalLogsTable } from "./PortalLogTable";
import { WorkflowLogTable } from "./WorkflowLogTable";

interface PortalLogsProps {}

const PortalLogs: React.FC<PortalLogsProps> = ({}) => {
  const { teamId } = useParams<{ teamId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get all URL parameters
  const timestamp = searchParams.get("time")
    ? Number(searchParams.get("time"))
    : undefined;
  const type = searchParams.get("type") ?? "portal";
  const portalLogId = searchParams.get("portalLogId");
  const workflowLogId = searchParams.get("workflowLogId");

  const portalLogService = usePortalLogService();
  const teamService = useTeams();
  const navigate = useNavigate();

  const [portalLogs, setPortalLogs] = useState<PortalLog[]>([]);
  const [workflowLogs, setWorkflowLogs] = useState<WorkflowLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [nextState, setNextState] = useState<AnimationState>("ready");
  const [backState, setBackState] = useState<AnimationState>("ready");
  const [error, setError] = useState("");

  const useWorkflows = type === "workflow";

  const limit = 50;

  const loadData = async () => {
    try {
      setLoading(true);
      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );

      if (useWorkflows) {
        const logs = await portalLogService.workflowLogRepo.getList(
          portalLogService.workflowLogPath(teamId!),
          { name: "createdAt", descending: true },
          undefined,
          limit,
          timestamp ? new Date(timestamp) : undefined
        );
        setWorkflowLogs(
          logs.filter((log) => filterExampleLogs(team ?? undefined, log))
        );
        setPortalLogs([]);
      } else {
        const logs = await portalLogService.portalLogRepo.getList(
          portalLogService.portalLogPath(teamId!),
          { name: "createdAt", descending: true },
          undefined,
          limit,
          timestamp ? new Date(timestamp) : undefined
        );
        setPortalLogs(
          logs.filter((log) => filterExampleLogs(team ?? undefined, log))
        );
        setWorkflowLogs([]);
      }
    } catch (e) {
      setError(e instanceof Error ? e.message : "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [timestamp, type]); // Now depends on type instead of useWorkflows

  const handleNextPage = () => {
    const logs = useWorkflows ? workflowLogs : portalLogs;
    const startAfter = logs[logs.length - 1].createdAt;

    // Preserve existing parameters when updating time
    setSearchParams((params) => {
      params.set("time", startAfter.getTime().toString());
      return params;
    });

    if (useWorkflows) {
      setWorkflowLogs([]);
    } else {
      setPortalLogs([]);
    }
  };

  const handleBack = () => {
    if (useWorkflows) {
      setWorkflowLogs([]);
    } else {
      setPortalLogs([]);
    }
    navigate(-1);
  };

  const handlePortalRowClick = (log: PortalLog) => {
    setSearchParams((params) => {
      params.set("portalLogId", log.id);
      params.delete("workflowLogId"); // Clear other log ID if exists
      return params;
    });
  };

  const handleWorkflowRowClick = (log: WorkflowLog) => {
    setSearchParams((params) => {
      params.set("workflowLogId", log.id);
      params.delete("portalLogId"); // Clear other log ID if exists
      return params;
    });
  };

  const handleCloseModal = () => {
    setSearchParams((params) => {
      params.delete("portalLogId");
      params.delete("workflowLogId");
      return params;
    });
  };

  const handleSwitchChange = (useWorkflows: boolean) => {
    setSearchParams((params) => {
      params.set("type", useWorkflows ? "workflow" : "portal");
      params.delete("portalLogId");
      params.delete("workflowLogId");
      params.delete("time");
      return params;
    });
  };

  const currentLogs = useWorkflows ? workflowLogs : portalLogs;

  return (
    <CommonContainer>
      <CommonHeader
        title="Logs"
        subtitle="Observe everything that your users see. Ai that's not a black box."
        sections={[{ name: "Logs", link: "" }]}
        teamId={teamId!}
        actions={[]}
      />
      <FailureModal
        shows={error !== ""}
        message={error}
        closed={() => setError("")}
      />
      {portalLogId && (
        <LogModal
          teamId={teamId!}
          logId={portalLogId}
          onClose={handleCloseModal}
        />
      )}
      {workflowLogId && (
        <WorkflowLogModal
          teamId={teamId!}
          logId={workflowLogId}
          onClose={handleCloseModal}
        />
      )}
      <div className="grid grid-cols-3">
        <CommonWorkflowPortalSwitch
          useWorkflows={useWorkflows}
          setUseWorkflows={handleSwitchChange}
        />
      </div>

      <div className="">
        {useWorkflows ? (
          <WorkflowLogTable
            logs={workflowLogs}
            loading={loading}
            onRowClick={handleWorkflowRowClick}
          />
        ) : (
          <PortalLogsTable
            logs={portalLogs}
            loading={loading}
            onRowClick={handlePortalRowClick}
          />
        )}
      </div>
      <div className="flex flex-row justify-between">
        <AnimatedButton
          title="Back"
          onClick={handleBack}
          buttonState={backState}
          setButtonState={setBackState}
          style="action"
          font="font-sans"
          classNameIn={`${timestamp ? "" : "invisible"}`}
        />
        <AnimatedButton
          title="Next"
          onClick={handleNextPage}
          buttonState={nextState}
          setButtonState={setNextState}
          style="action"
          font="font-sans"
          classNameIn={`${currentLogs.length == limit ? "" : "invisible"}`}
        />
      </div>
      {currentLogs.length == 0 && loading == false && (
        <CommonNoData teamId={teamId ?? ""} />
      )}
    </CommonContainer>
  );
};

export default PortalLogs;
