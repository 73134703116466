import { Edge, Node } from "reactflow";
import { APINodeType } from "./APINode";
import { PortalNodeType } from "./PortalNode";
import { SearchNodeType } from "./SearchNode";
import { WebsiteNodeType } from "./WebsiteNode";
import { ActionNodeType } from "./ActionNode";

export type OutputNodeType =
  | typeof PortalNodeType
  | typeof WebsiteNodeType
  | typeof SearchNodeType
  | typeof APINodeType
  | typeof ActionNodeType;

export const OUTPUT_NODE_TYPES: OutputNodeType[] = [
  PortalNodeType,
  WebsiteNodeType,
  SearchNodeType,
  APINodeType,
  ActionNodeType,
];

export function findAvailableOutputNodes(
  targetNodeId: string,
  nodes: Node[],
  edges: Edge[],
  outputTypes: OutputNodeType[] = OUTPUT_NODE_TYPES
): Node[] {
  const visitedNodes = new Set<string>();
  const validNodes: Node[] = [];
  const nodeMap = new Map(nodes.map((node) => [node.id, node]));

  function traverse(nodeId: string) {
    if (visitedNodes.has(nodeId)) return;
    visitedNodes.add(nodeId);

    const node = nodeMap.get(nodeId);
    if (
      node &&
      outputTypes.includes(node.data.type as OutputNodeType) &&
      node.id !== targetNodeId
    ) {
      validNodes.push(node);
    }

    const incomingEdges = edges.filter((edge) => edge.target === nodeId);
    for (const edge of incomingEdges) {
      traverse(edge.source);
    }
  }

  traverse(targetNodeId);
  return validNodes;
}
