import { getFirestore } from "firebase/firestore";
import firebaseApp from "../Firebase";
import { PortalTest } from "../models/PortalTest";
import { TestRun } from "../models/TestRun";
import { WorkflowTest, WorkflowTestRun } from "../models/WorkflowTest";
import { FirestoreRepository } from "../repos/FirestoreRepository";
import { Repository } from "../repos/Repository";
import { PortalTestSuite } from "../models/PortalTestSuite";
import { NetworkHelper } from "../utils/NetworkHelper";

export interface TestService {
  // Portal test repositories
  testRunRepo: Repository<TestRun>;
  testRepo: Repository<PortalTest>;
  testSuiteRepo: Repository<PortalTestSuite>;

  // Workflow test repositories
  workflowTestRunRepo: Repository<WorkflowTestRun>;
  workflowTestRepo: Repository<WorkflowTest>;

  // Portal test paths
  testRunPath(teamId: string, portalId: string): string;
  testPath(teamId: string, portalId: string): string;
  testSuitePath(teamId: string, portalId: string): string;

  // Workflow test paths
  workflowTestRunPath(teamId: string, workflowId: string): string;
  workflowTestPath(teamId: string, workflowId: string): string;

  // Run methods
  runTest(
    testId: string,
    portalId: string,
    versionId: string,
    teamId: string
  ): Promise<void>;

  runWorkflowTest(
    testId: string,
    workflowId: string,
    versionId: string,
    teamId: string
  ): Promise<void>;

  runWorkflowAPITest(
    teamId: string,
    url: string,
    headers: { [key: string]: string },
    outputs: { [key: string]: string },
    userSecrets: { [key: string]: string },
    variables: { [key: string]: string },
    method: string,
    body?: string
  ): Promise<string>;
}

export class FirestoreTestService implements TestService {
  networkHelper = new NetworkHelper();

  // Portal test repositories
  testRunRepo = new FirestoreRepository<TestRun>(getFirestore(firebaseApp));
  testRepo = new FirestoreRepository<PortalTest>(getFirestore(firebaseApp));
  testSuiteRepo = new FirestoreRepository<PortalTestSuite>(
    getFirestore(firebaseApp)
  );

  // Workflow test repositories
  workflowTestRunRepo = new FirestoreRepository<WorkflowTestRun>(
    getFirestore(firebaseApp)
  );
  workflowTestRepo = new FirestoreRepository<WorkflowTest>(
    getFirestore(firebaseApp)
  );

  // Portal test paths
  testRunPath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/test-runs`;
  }

  testPath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/tests`;
  }

  testSuitePath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/testSuites`;
  }

  // Workflow test paths
  workflowTestRunPath(teamId: string, workflowId: string): string {
    return `teams/${teamId}/workflows/${workflowId}/test-runs`;
  }

  workflowTestPath(teamId: string, workflowId: string): string {
    return `teams/${teamId}/workflows/${workflowId}/tests`;
  }

  async runTest(
    testId: string,
    portalId: string,
    versionId: string,
    teamId: string
  ): Promise<void> {
    await this.networkHelper.sendRequest("/portal-tests/run", "POST", {
      testId,
      versionId,
      teamId,
      portalId,
    });
  }

  async runWorkflowTest(
    testId: string,
    workflowId: string,
    versionId: string,
    teamId: string
  ): Promise<void> {
    await this.networkHelper.sendRequest("/workflow-tests/run", "POST", {
      testId,
      versionId,
      teamId,
      workflowId,
      ipAddress: "", // Required by the backend
    });
  }

  async runWorkflowAPITest(
    teamId: string,
    url: string,
    headers: { [key: string]: string },
    outputs: { [key: string]: string },
    userSecrets: { [key: string]: string },
    variables: { [key: string]: string },
    method: string,
    body?: string
  ): Promise<string> {
    const serverResponse = await this.networkHelper.sendRequest(
      "/api-tests/run",
      "POST",
      {
        url,
        headers,
        teamId,
        outputs,
        userSecrets,
        body,
        method,
        variables,
      }
    );
    const { response } = await serverResponse.json();
    return response;
  }
}
