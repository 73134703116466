import React, { useState, useRef, useEffect } from "react";
import Icon, { IconType } from "../../Icon";
import { useReactFlow } from "reactflow";
import { findAvailableOutputNodes } from "../Map/Nodes/NodeUtils";

export type TextInput = {
  type: "text";
  value?: string;
};

export type OutputInput = {
  type: "output";
  nodeId?: string;
};

export type VariableInput = {
  type: "variable";
  variableId?: string;
};

export type SelectedInput = TextInput | OutputInput | VariableInput;

interface VariableInputSelectorProps {
  availableVariables: string[];
  selectedInput: SelectedInput;
  setSelectedInput: (selectedInput: SelectedInput | undefined) => void;
  currentNodeId: string | undefined;
}

interface OutsideClickListenerProps {
  clickedOutside: () => void;
  children: React.ReactNode;
}

const OutsideClickListener: React.FC<OutsideClickListenerProps> = ({
  clickedOutside: setIsOpen,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div className="relative" ref={ref}>
      {children}
    </div>
  );
};

// TypeSelector component
const TypeSelector: React.FC<{
  selectedType: string;
  setSelectedInput: (selectedInput: SelectedInput) => void;
}> = ({ selectedType, setSelectedInput }) => {
  const [isOpen, setIsOpen] = useState(false);
  const typeOptions: SelectedInput[] = [
    { type: "output" },
    { type: "text" },
    { type: "variable" },
  ];

  const icon: IconType =
    selectedType == "variable"
      ? "workflows"
      : selectedType == "text"
      ? "quote"
      : "reply-all";

  const title =
    selectedType == "variable"
      ? "Variable"
      : selectedType == "text"
      ? "Static Text"
      : "Output";

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        className="h-10 w-full px-3 py-2 bg-gray-50 rounded-l-lg border-l border-t border-b border-gray-300 flex items-center justify-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-row gap-1 items-center">
          <Icon type={icon} className={`text-gray-400 size-5`} />
          <div className="text-gray-700 text-sm font-normal font-gooper truncate">
            {title}
          </div>
        </div>
        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } transition-all text-gray-400`}
        />
      </div>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
          {typeOptions.map((typeOption) => (
            <div
              key={typeOption.type}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm font-normal font-['Gooper Trial SemiCondensed'] truncate"
              onClick={() => {
                setSelectedInput(typeOption);
                setIsOpen(false);
              }}
            >
              {typeOption.type}
            </div>
          ))}
        </div>
      )}
    </OutsideClickListener>
  );
};

// TextInput component
const TextInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => (
  <input
    className="h-10 placeholder:font-normal border-gray-300 w-full font-medium bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    type="text"
    placeholder="text..."
    id="textInput"
    autoComplete="off"
  />
);

const OutputInput: React.FC<{
  selectedNodeId: string | undefined;
  currentNodeId: string | undefined;
  setSelectedInput: (input: OutputInput) => void;
}> = ({ selectedNodeId, currentNodeId, setSelectedInput }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getNodes, getEdges } = useReactFlow();

  const nodes = getNodes();
  const edges = getEdges();

  const outputNodes = findAvailableOutputNodes(
    currentNodeId ?? "",
    nodes,
    edges
  );

  const selectedNode = nodes.find((n) => n.id === selectedNodeId);
  const isEmpty = !selectedNode;

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        onClick={() => setIsOpen(true)}
        className="h-10 relative border-gray-300 w-full bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm flex items-center justify-between cursor-pointer"
      >
        {isEmpty ? (
          <div className={`text-gray-500 truncate items-center justify-center`}>
            select beam
          </div>
        ) : (
          <div
            className={`truncate text-sx font-medium text-gray-700 p-1 items-center justify-center bg-gray-50 rounded-lg`}
          >
            {selectedNode.data.title}
          </div>
        )}

        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } text-gray-400 transition-all`}
        />
        {isOpen && (
          <div className="absolute left-0 right-0 top-10 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            {outputNodes.map((node) => (
              <div
                key={node.id}
                className="px-3 py-2 text-gray-700 hover:bg-green-100 cursor-pointer text-sm font-normal font-gooper truncate"
                onClick={(e) => {
                  setSelectedInput({
                    type: "output",
                    nodeId: node.id,
                  });
                  setIsOpen(false);
                  e.stopPropagation();
                }}
              >
                {node.data.title}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickListener>
  );
};

// VariableInput component (updated with chevron)
const VariableInput: React.FC<{
  selectedVariableId: string | undefined;
  availableVariables: string[];
  setSelectedInput: (input: VariableInput) => void;
}> = ({ selectedVariableId, availableVariables, setSelectedInput }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isEmpty = selectedVariableId == undefined;

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        onClick={() => setIsOpen(true)}
        className="h-10 relative border-gray-300 w-full bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm flex items-center justify-between cursor-pointer"
      >
        {isEmpty ? (
          <div className={`text-gray-500 truncate items-center justify-center`}>
            variable...
          </div>
        ) : (
          <div
            className={`truncate text-sx font-medium text-gray-700 py-1 px-2 items-center justify-center bg-gray-50 rounded-lg`}
          >
            {selectedVariableId}
          </div>
        )}
        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } text-gray-400 transition-all`}
        />
        {isOpen && (
          <div className="absolute left-0 right-0 top-10 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            {availableVariables.map((variable) => (
              <div
                key={variable}
                className="px-3 py-2 text-gray-700 hover:bg-green-100 cursor-pointer text-sm font-normal font-gooper truncate"
                onClick={(e) => {
                  setSelectedInput({
                    type: "variable",
                    variableId: variable,
                  });
                  setIsOpen(false);
                  e.stopPropagation();
                }}
              >
                {variable}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickListener>
  );
};

// Main VariableInputSelector component
const VariableInputSelector: React.FC<VariableInputSelectorProps> = ({
  availableVariables,
  selectedInput,
  setSelectedInput,
  currentNodeId,
}) => {
  const renderInput = () => {
    switch (selectedInput.type) {
      case "text":
        return (
          <TextInput
            value={selectedInput.value || ""}
            onChange={(value) => setSelectedInput({ type: "text", value })}
          />
        );
      case "output":
        return (
          <OutputInput
            selectedNodeId={selectedInput.nodeId}
            currentNodeId={currentNodeId}
            setSelectedInput={(input) => setSelectedInput(input)}
          />
        );
      case "variable":
        return (
          <VariableInput
            selectedVariableId={selectedInput.variableId}
            availableVariables={availableVariables}
            setSelectedInput={(input) => setSelectedInput(input)}
          />
        );
    }
  };

  return (
    <div className="grid grid-cols-2">
      <TypeSelector
        selectedType={selectedInput.type}
        setSelectedInput={setSelectedInput}
      />
      {renderInput()}
    </div>
  );
};

export default VariableInputSelector;
