import { motion } from "framer-motion";
import Icon from "../Icon";
import { ReactNode } from "react";

const SelectionBoard: React.FC<{
  selected: string | undefined;
  items: string[];
  setSelected: (item: string) => void;
  columns: number;
  textSize: string;
  animate?: boolean; // Optional prop to control animation
}> = ({ items, selected, setSelected, columns, textSize, animate = false }) => {
  let gridClass = "";
  switch (columns) {
    case 2:
      gridClass = "grid-cols-2";
      break;
    case 3:
      gridClass = "grid-cols-3";
      break;
    case 4:
      gridClass = "grid-cols-4";
      break;
    case 5:
      gridClass = "grid-cols-5";
      break;
    default:
      gridClass = "grid-cols-1"; // Fallback to one column if none specified
  }

  const button = (item: string): ReactNode => {
    return (
      <div className="flex items-center gap-2">
        {item === selected ? (
          <Icon type="check-line" className="text-green-500" />
        ) : (
          <Icon type="simple-plus" className="text-blue-400" />
        )}
        <div className={`text-gray-800 ${textSize} font-gooper`}>{item}</div>
      </div>
    );
  };

  return (
    <div className={`w-1/2 min-w-[360px] grid ${gridClass} gap-4`}>
      {items.map((item, index) =>
        animate ? (
          <motion.div
            id={`item${index + 1}`}
            key={item}
            onClick={() => setSelected(item)}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.3,
              delay: index * 0.1,
            }}
          >
            <motion.button
              className={`p-4 ${
                item === selected ? "bg-green-50" : "bg-white"
              } rounded-lg cursor-pointer w-full h-full`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 1.02 }}
            >
              {button(item)}
            </motion.button>
          </motion.div>
        ) : (
          <div
            id={`item${index + 1}`}
            key={item}
            className={`p-4 ${
              item === selected ? "bg-green-50" : "bg-white"
            } rounded-lg cursor-pointer`}
            onClick={() => setSelected(item)}
          >
            {button(item)}
          </div>
        )
      )}
    </div>
  );
};

export default SelectionBoard;
