import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { TeamSettingsSections } from "./TeamSettingsSections";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import FailureModal from "../FailureModal";
import { CommonSpinner } from "../Common/CommonLoading";
import Icon from "../Icon";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { CommonConfirmationModal } from "../Common/CommonConfirmationModal";
import { AppPath } from "../../models/AppPath";

interface CustomSecretModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (key: string, value: string) => void;
}

const AddCustomSecretModal: React.FC<CustomSecretModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(key, value);
    setKey("");
    setValue("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h3 className="text-lg font-medium mb-4">Add Custom Secret</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Key
            </label>
            <input
              type="text"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              className="w-full rounded-md border border-gray-300 px-3 py-2"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Value
            </label>
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="w-full rounded-md border border-gray-300 px-3 py-2"
              required
            />
          </div>
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              Add Secret
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const TeamSettingsCustomSecrets: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const teamService = useTeams();

  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [createState, setCreateState] = useState<AnimationState>("ready");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [deletionKey, setDeletionKey] = useState<string>();

  const loadData = async () => {
    try {
      const fetchedTeam = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      if (fetchedTeam) {
        setTeam(fetchedTeam);
      }
    } catch (e) {
      setError("Failed to load team");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [teamService]);

  const customSecrets = team?.customSecretsUsed ?? {};
  const listData = Object.keys(customSecrets)
    .map((key) => ({ key, used: customSecrets[key] }))
    .sort((a, b) => a.key.localeCompare(b.key));

  const handleCreateSecret = async (key: string, value: string) => {
    setCreateState("loading");
    try {
      await teamService.addCustomSecret(teamId!, key, value);
      setCreateState("success");
      loadData();
    } catch {
      setCreateState("error");
    }
  };

  const handleDeleteSecret = async (key: string) => {
    try {
      setDeletionKey(undefined);
      await teamService.deleteCustomSecret(teamId!, key);
      setCreateState("success");
      loadData();
    } catch {
      setCreateState("error");
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title="Custom Secrets"
        subtitle="Manage your team's custom secrets. These are encrypted and can be used across your applications."
        sections={[
          { name: "Team Settings", link: AppPath.settings(teamId ?? "") },
          { name: "Custom Secrets", link: AppPath.customSecrets(teamId ?? "") },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title="Add Secret"
            onClick={() => setIsAddModalOpen(true)}
            buttonState={createState}
            setButtonState={setCreateState}
            style="action"
            key="create"
            id="createButton"
          />,
        ]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      <TeamSettingsSections selectedId="custom-secrets" />

      <AddCustomSecretModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleCreateSecret}
      />

      <table className="border rounded-3xl border-gray-200 w-full bg-gray-200">
        <thead className="border-b border-gray-200">
          <tr>
            <Header title="Key" />
            <Header title="Status" />
            <Header title="Action" className="w-1" padding="p-2" />
          </tr>
          {loading && listData.length == 0 && (
            <tr className="bg-gray-0">
              <td className="border-b border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
              <td className="border-b border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
              <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {listData.map((secret, index) => (
            <tr key={index} className="bg-gray-0" id={`secretCell${index}`}>
              <td className="border-b text-gray-500 border-gray-200 p-3 font-normal text-sm">
                {secret.key}
              </td>
              <td className="border-b p-3 text-sm text-gray-500 font-normal">
                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  Active
                </span>
              </td>
              <td className="relative border-b border-gray-200 pl-6 font-medium text-sm">
                <CommonConfirmationModal
                  title="Are you sure?"
                  message="Deleting this secret will break your applications if they're still using it."
                  onCancel={() => setDeletionKey(undefined)}
                  isOpen={deletionKey === secret.key}
                  onConfirm={() => handleDeleteSecret(secret.key)}
                  confirmStyle="destructive"
                />
                <button
                  id={`deleteButton${index}`}
                  onClick={() => setDeletionKey(secret.key)}
                  className="size-7 rounded-full bg-red-50 hover:bg-blue-100 items-center flex justify-center"
                >
                  <Icon type="trash" className="size-5 text-red-500" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CommonContainer>
  );
};

const Header: React.FC<{
  title: string;
  className?: string;
  padding?: string;
}> = ({ title, className, padding }) => {
  return (
    <th
      className={`${
        padding ?? "p-3"
      } bg-gray-50 text-left p4 text-xs font-medium text-gray-500 uppercase tracking-widest ${className}`}
    >
      {title}
    </th>
  );
};
