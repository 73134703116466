import React from "react";
import {
  getBezierPath,
  EdgeProps,
  useReactFlow,
  Node,
  Position,
} from "reactflow";
import { createSelectorNode } from "../Nodes/SelectorNode";
import Icon from "../../../Icon";
import { StartNodeData, StartNodeType } from "../Nodes/StartNode";

export const WorkflowAddNodeEdgeType = "workflowAddNodeEdgeType";

export const WorkflowAddNodeEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  source,
  selected,
  target,
  sourceHandleId,
}) => {
  const { getNode, setNodes } = useReactFlow();

  // Determine if this is an action edge
  const isActionEdge = sourceHandleId?.includes("INDEX");

  // Override sourcePosition if this is an action edge
  const effectiveSourcePosition = isActionEdge
    ? Position.Right
    : sourcePosition;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition: effectiveSourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const handlePlusClick = () => {
    const sourceNode = getNode(source)!;
    const startNode = getNode(StartNodeType) as Node<StartNodeData>;
    const selectionNode = createSelectorNode(
      sourceNode,
      startNode.data.teamId,
      target,
      id,
      sourceHandleId ?? undefined
    );
    setNodes((nds) => nds.concat(selectionNode));
  };

  return (
    <>
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#D1D5DB" />
        </marker>
      </defs>
      <g className={`group ${selected ? "selected" : ""}`}>
        <path
          d={edgePath}
          fill="none"
          stroke="transparent"
          strokeWidth={20}
          className="cursor-pointer"
        />
        <path
          id={id}
          style={{
            ...style,
            strokeWidth: 2,
            stroke: "#D1D5DB",
          }}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd="url(#arrow)"
        />
        <foreignObject
          width={40}
          height={40}
          x={labelX - 20}
          y={labelY - 20}
          className="edgebutton-foreignobject opacity-0 group-hover:opacity-100 group-[.selected]:opacity-100 transition-opacity duration-200"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div className="flex items-center justify-center w-full h-full">
            <button
              onClick={(event) => {
                event.stopPropagation();
                handlePlusClick();
              }}
            >
              <Icon
                type="plus-inverted"
                className="text-blue-500 bg-gray-0 rounded-full"
              />
            </button>
          </div>
        </foreignObject>
      </g>
    </>
  );
};
