import React, { useState } from "react";
import TeamOnboardingPage from "./TeamOnboardingPage";
import { TeamRole } from "../../models/Team";
import MemberInput from "./MemberInput";
import { EnteredMembers, TeamOnboardingStep } from "./TeamOnboarding";
import { SubscriptionOption } from "../../models/SubscriptionOption";
import Icon from "../Icon";
import { NextButton } from "../Onboarding/NextButton";
import { motion } from "framer-motion";

const TeamAddMembers: React.FC<{
  gotMembers: (members: EnteredMembers) => void;
  progress: number;
  isLoading: boolean;
  subscription: SubscriptionOption;
  teamMembers: EnteredMembers;
  setTeamMembers: (members: EnteredMembers) => void;
  setStep: (step: TeamOnboardingStep) => void;
}> = ({
  gotMembers,
  progress,
  isLoading,
  subscription,
  teamMembers,
  setTeamMembers,
  setStep,
}) => {
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    const allEmailsValid = teamMembers.every(
      (member) => member.email.match(/.+@.+/) || !member.email
    );
    if (!allEmailsValid) {
      setError("Please fix or remove any invalid emails");
      return;
    }
    gotMembers(teamMembers);
  };
  const gridClass = subscription.seats < 6 ? "grid-cols-1" : "grid-cols-2";
  const handleSetEmail = (index: number, newEmail: string) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index].email = newEmail;
    setTeamMembers(newTeamMembers);
  };

  const handleSetRole = (index: number, newRole: TeamRole) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index].role = newRole;
    setTeamMembers(newTeamMembers);
  };

  const handleSkip = () => {
    gotMembers([]);
  };

  const handleBack = () => {
    setStep(TeamOnboardingStep.Subscriptions);
  };

  const remainingMessage = () => {
    const remaining =
      subscription.seats -
      teamMembers.filter((tm) => tm.email != "").length -
      1;
    return `${remaining} seats remaining`;
  };

  return (
    <TeamOnboardingPage
      error={error}
      title={"Let's make your first team"}
      headline="Invite your colleagues"
      message={`Beaming is more fun when you do it together.\n Invite your team members and start collaborating right away.`}
      isLoading={isLoading}
      progress={progress}
      setError={setError}
      didSkip={handleSkip}
      didClickBack={handleBack}
      skipMessage={"I'll add them later"}
    >
      <div className="px-2.5 py-1 bg-gray-100 gap-1 rounded-md flex items-center justify-center">
        <Icon type="users" className="text-gray-500" />

        <div className="text-center text-gray-500 text-xs font-normal leading-none">
          {remainingMessage()}
        </div>
      </div>
      <div
        className={`w-1/2 min-w-[360px] grid ${gridClass} gap-4 items-center`}
      >
        {teamMembers.map((member, index) => (
          <motion.div
            id={`item${index + 1}`}
            key={index}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 0.3,
              delay: index * 0.1,
            }}
          >
            <MemberInput
              key={`memberInput${index}`}
              selectedRole={member.role}
              email={member.email}
              setEmail={(email) => handleSetEmail(index, email)}
              setSelectedRole={(role) => handleSetRole(index, role)}
              index={index}
            />
          </motion.div>
        ))}
      </div>

      <NextButton onClick={handleSubmit} />
    </TeamOnboardingPage>
  );
};

export default TeamAddMembers;
