import { useNavigate } from "react-router-dom";
import { Portal } from "../../../models/Portal";
import {
  PortalVersion,
  PortalVersionStatus,
} from "../../../models/PortalVersion";
import { useState } from "react";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { usePortalService } from "../../../contexts/PortalContext";
import { LiveConfirmationModal } from "./LiveConfirmationModal";
import Icon from "../../Icon";
import { PortalDetalActionMenu } from "./PortalDetailActionMenu";
import { CommonConfirmationModal } from "../../Common/CommonConfirmationModal";
import { Team } from "../../../models/Team";
import { AppPath } from "../../../models/AppPath";
import { APIModal } from "./APIModal";

export const PortalDetailActions: React.FC<{
  portal: Portal | undefined;
  version: PortalVersion | undefined;
  team: Team | undefined;
  updatedVersion: (version: PortalVersion) => void;
  loadData: () => Promise<void>;
}> = ({ portal, version, updatedVersion, loadData, team }) => {
  const navigate = useNavigate();
  const portalService = usePortalService();

  const [publishState, setPublishState] = useState<AnimationState>("ready");
  const [testcenterState, setTestcenterState] =
    useState<AnimationState>("ready");
  const [duplicateState, setDuplicateState] = useState<AnimationState>("ready");

  const [goLiveModal, setGoLiveModal] = useState(false);
  const [apiKeyModal, setAPIKeyModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [apiModal, setAPIModal] = useState(false);

  if (!portal || !version || !team) {
    return <></>;
  }

  const setStatus = async (
    status: PortalVersionStatus,
    setStatusState: (value: AnimationState) => void
  ) => {
    setStatusState("loading");
    await portalService.updateVersion(
      { ...version!, status },
      version.id!,
      team.id!,
      portal.id!
    );
    await handleVersionUpdate({ ...version, status });
    setStatusState("success");
  };

  const handleVersionUpdate = async (newVersion: PortalVersion) => {
    await loadData();
    updatedVersion({ ...newVersion });
  };

  const handleStatusChange = async () => {
    let newStatus: PortalVersionStatus = version.status;
    if (version.status === "ARCHIVED") {
      newStatus = "DRAFT";
    } else if (version.status === "DRAFT") {
      newStatus = "READY";
    }
    if (
      newStatus === "READY" &&
      team.secretsUsed?.[version.configId] == undefined
    ) {
      setAPIKeyModal(true);
      return;
    }
    await setStatus(newStatus, setPublishState);
  };

  const handlePublish = async () => {
    setPublishState("loading");
    await portalService.publishVersion(version.id!, team.id!, portal.id!);
    await loadData();
    setPublishState("success");
    setAPIModal(true);
  };

  const isLive =
    portal.currentVersionId == version.id &&
    portal.currentVersionId != undefined;

  const handleTestCenter = async () => {
    setTestcenterState("loading");
    navigate(
      AppPath.testCenter(team.id!, "portal", portal.id!, undefined, [
        version!.id!,
      ])
    );
  };

  const handleAddAPIKey = async () => {
    setAPIKeyModal(false);
    navigate(AppPath.aiProviders(team.id!));
  };

  const handlePublishButton = () => {
    if (team.secretsUsed?.[version.configId] == undefined) {
      setAPIKeyModal(true);
      return;
    }
    setGoLiveModal(true);
  };

  const handleDuplication = async () => {
    setDuplicateState("loading");
    try {
      const newVersion = await portalService.duplicateVersion(
        team.id!,
        portal.id!,
        version.id!
      );
      setDuplicateState("ready");
      navigate(AppPath.portalVersion(team.id!, portal.id!, newVersion.id!));
    } catch {
      setDuplicateState("error");
    }
  };

  // Determine the action button based on the status
  let actionButton;
  if (version.status === "READY" || version.status === "DRAFT") {
    actionButton = (
      <AnimatedButton
        title="Publish Version"
        onClick={() => handlePublishButton()}
        buttonState={publishState}
        setButtonState={setPublishState}
        style={"action"}
        key="publish"
        id="publishButton"
      />
    );
  } else if (version.status === "ARCHIVED") {
    actionButton = (
      <AnimatedButton
        title="Unarchive"
        onClick={() => handleStatusChange()}
        buttonState={publishState}
        setButtonState={setPublishState}
        style={"action"}
        key="unarchive"
        id="unarchiveButton"
      />
    );
  }

  return (
    <div className="flex flex-col gap-1 w-60 pr-4 pb-2 -mt-2">
      <LiveConfirmationModal
        isOpen={goLiveModal}
        onCancel={() => setGoLiveModal(false)}
        onConfirm={() => handlePublish()}
        currentLiveVersion={portal?.currentVersionData}
      />

      <CommonConfirmationModal
        isOpen={apiKeyModal}
        title="Almost! API Key needs to be set."
        message="We are missing your API Key to make this version live. You will have to set to draft in order to take it online."
        onCancel={() => setAPIKeyModal(false)}
        onConfirm={() => handleAddAPIKey()}
        confirmStyle={"action"}
        confirmIcon="portals"
        confirmTitle="Set key"
      />
      <APIModal shows={apiModal} setShows={setAPIModal} portal={portal} />

      {actionButton}

      <div className="flex flex-row gap-1 w-full relative">
        <AnimatedButton
          title={"Duplicate Version"}
          onClick={() => handleDuplication()}
          buttonState={duplicateState}
          setButtonState={setDuplicateState}
          style={"secondary"}
          key="duplicate"
          id="duplicateButton"
          classNameIn="w-full"
          leftIcon="versions"
        />

        <PortalDetalActionMenu
          version={version}
          portal={portal}
          teamId={team.id!}
          shows={showMenu}
          setShows={setShowMenu}
          updatedVersion={handleVersionUpdate}
          isLive={isLive}
        />

        <button
          id="actionMenuButton"
          className="bg-blue-50 hover:bg-blue-100 transition-all duration-200 w-12 rounded-lg items-center justify-center flex"
          onClick={() => setShowMenu(true)}
        >
          <Icon type="dots-horizontal" />
        </button>
      </div>

      <AnimatedButton
        title={"Open Test Center"}
        onClick={() => handleTestCenter()}
        buttonState={testcenterState}
        setButtonState={setTestcenterState}
        style={"transparent-action"}
        key="analytics"
        leftIcon="adjustments"
      />
    </div>
  );
};
