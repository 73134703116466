import { useState } from "react";
import { TeamVariables } from "../../../models/Team";
import Icon from "../../Icon";

export const VariableInput: React.FC<{
  usedVariables?: { [id: string]: TeamVariables };
  addVariable: (variable: string) => void;
  removeVariable: (variable: string) => void;
  teamVariables: { [id: string]: TeamVariables };
  addTeamVariable: (variable: string) => void;
}> = ({
  usedVariables,
  teamVariables,
  addVariable,
  addTeamVariable,
  removeVariable,
}) => {
  const [newVariable, setNewVariable] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const availableVariables = Object.values(teamVariables).map((v) => v.id);
  const variables = Object.values(usedVariables ?? {}).map((v) => v.id);

  const filteredVariables = availableVariables
    .filter(
      (variable) =>
        variable.toLowerCase().includes(newVariable.toLowerCase()) &&
        !variables.includes(variable)
    )
    .slice(0, 4);

  const handleNewVariable = () => {
    addVariable(newVariable);
    addTeamVariable(newVariable);
    setNewVariable("");
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="text-sm text-gray-700 font-medium">
        Workflow Variables
      </div>
      <div className="text-xs text-gray-400">
        These will come from the API & can be used by each Beam.
      </div>
      <div className="relative">
        <input
          className={`border-gray-300 w-full focus:border-blue-500 bg-gray-50 text-gray-700 border rounded-md p-2 leading-tight text-sm focus:ring-0 focus:outline-none h-10`}
          value={newVariable}
          onChange={(e) => setNewVariable(e.target.value)}
          type="text"
          placeholder="Search for your variables"
          id="workflowVariableInput"
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            // Delay the blur event to allow the click to be captured
            setTimeout(() => setIsFocused(false), 100);
          }}
          autoComplete="off"
        />
        {isFocused && (filteredVariables.length > 0 || newVariable != "") && (
          <div className="absolute w-full text-left flex flex-col items-start bg-gray-0 border border-gray-300 rounded-md mt-1 z-50">
            {filteredVariables.map((v) => (
              <button
                onMouseDown={() => addVariable(v)} // Use onMouseDown to prevent onBlur from firing first
                key={v}
                className="py-2 px-4 w-full items-start text-left hover:bg-blue-25 text-gray-700"
              >
                {v}
              </button>
            ))}
            {newVariable != "" && (
              <button
                onClick={handleNewVariable}
                className="cursor-pointer flex flex-row gap-2 border-t border-gray-200 w-full py-3 items-center px-4"
              >
                <Icon
                  type="puzzle-piece"
                  className="text-blue-500 fill-blue-500 size-3"
                />
                <div className="text-sm text-blue-500">{`Add ${newVariable} to team variables`}</div>
              </button>
            )}
          </div>
        )}
        <div className="flex flex-wrap gap-1 mt-2">
          {Object.values(usedVariables ?? {}).map((v) => {
            return (
              <div
                key={v.id}
                className="h-6 px-1.5 py-0.5 bg-gray-100 rounded justify-start items-center gap-1 inline-flex"
              >
                <div className="text-center text-gray-500 text-xs font-medium font-['General Sans'] leading-[18px]">
                  {v.id}
                </div>
                <button
                  onClick={() => removeVariable(v.id)}
                  className="w-2.5 h-2.5 relative"
                >
                  <Icon
                    type="x-no-circle"
                    className="text-gray-500 fill-gray-500 size-3"
                  />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
