import {
  doc,
  getDocs,
  onSnapshot,
  collection,
  query,
  orderBy,
  Firestore,
  deleteDoc,
  addDoc,
  setDoc,
  where,
  DocumentData,
  Query,
  updateDoc,
  deleteField,
  limit,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { docToData, getDocData } from "../utils/FirestoreUtils";
import { Repository, WhereQuery } from "./Repository";

export class FirestoreRepository<T extends { [x: string]: any }>
  implements Repository<T>
{
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  async getList(
    path: string,
    sortBy?: { name: string; descending: boolean },
    whereQueries?: WhereQuery[],
    queryLimit?: number,
    startAfterValue?: any
  ): Promise<T[]> {
    let q: Query<DocumentData> = collection(this.db, path);

    whereQueries?.forEach((whereQuery) => {
      q = query(q, where(whereQuery.key, whereQuery.filter, whereQuery.value));
    });

    if (sortBy) {
      q = query(q, orderBy(sortBy.name, sortBy.descending ? "desc" : "asc"));
    }

    if (queryLimit) {
      q = query(q, limit(queryLimit));
    }

    if (startAfterValue) {
      q = query(q, startAfter(startAfterValue));
    }

    if (process.env.NODE_ENV === "development") {
      console.log(`GET LIST ${path}`);
    }

    const snapshot = await getDocs(q);
    return snapshot.docs.map(docToData) as T[];
  }

  async get(path: string, id: string): Promise<T | null> {
    if (process.env.NODE_ENV === "development") {
      console.log(`GET ${path}/${id}`);
    }
    return getDocData<T>(`${path}/${id}`, this.db);
  }

  async delete(path: string, id: string): Promise<void> {
    if (process.env.NODE_ENV === "development") {
      console.log(`DELETE ${path}/${id}`);
    }
    return deleteDoc(doc(this.db, path, id));
  }

  async deleteList(path: string): Promise<void> {
    if (process.env.NODE_ENV === "development") {
      console.log(`DELETE LIST ${path}`);
    }
    const docs = await getDocs(collection(this.db, path));
    const deletions = docs.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletions);
  }

  async update(
    data: Partial<T>,
    path: string,
    id: string,
    createIfNeeded?: boolean
  ): Promise<void> {
    const docRef = doc(this.db, path, id);

    if (process.env.NODE_ENV === "development") {
      console.log(`UDPATE ${path}/${id}`);
    }

    // Prepare the data for update, converting 'undefined' to 'FieldValue.delete()'
    const updateData = Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = value === undefined ? deleteField() : value;
      return acc;
    }, {} as { [key: string]: any });
    if (createIfNeeded) {
      await setDoc(docRef, updateData, { merge: true });
    } else {
      await updateDoc(docRef, updateData);
    }
  }

  async set(data: T, path: string, id: string): Promise<void> {
    if (process.env.NODE_ENV === "development") {
      console.log(`SET ${path}/${id}`);
    }
    const docRef = doc(this.db, path, id);
    await setDoc(docRef, data as any);
  }

  async create(data: T, path: string): Promise<T> {
    if (process.env.NODE_ENV === "development") {
      console.log(`CREATE ${path}`);
    }
    const docRef = await addDoc(collection(this.db, path), data as any);
    return { ...data, id: docRef.id };
  }

  observeList(
    path: string,
    callback: (list: T[]) => void,
    sortBy?: { name: string; descending: boolean },
    queryLimit?: number,
    startAfterValue?: any,
    whereQueries?: WhereQuery[]
  ): () => void {
    let q = query(collection(this.db, path));

    if (process.env.NODE_ENV === "development") {
      console.log(`OBSERVE LIST ${path}`);
    }

    if (sortBy) {
      q = query(
        collection(this.db, path),
        orderBy(sortBy.name, sortBy.descending ? "desc" : "asc")
      );
    }

    if (queryLimit) {
      q = query(q, limit(queryLimit));
    }

    if (startAfterValue) {
      q = query(q, startAfter(startAfterValue));
    }

    whereQueries?.forEach((whereQuery) => {
      q = query(q, where(whereQuery.key, whereQuery.filter, whereQuery.value));
    });

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const dataList: T[] = snapshot.docs.map(docToData) as T[];
      callback(dataList);
    });

    const unsubAndLog = () => {
      unsubscribe();
      if (process.env.NODE_ENV === "development") {
        console.log(`UNSUBBING LIST ${path}`);
      }
    };

    return unsubAndLog; // This returned function can be used to unsubscribe from updates
  }

  observe(path: string, id: string, callback: (object: T) => void): () => void {
    const docRef = doc(this.db, path, id);

    if (process.env.NODE_ENV === "development") {
      console.log(`OBSERVE ${path}/${id}`);
    }

    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        callback(docToData<T>(docSnapshot));
      }
    });

    const unsubAndLog = () => {
      unsubscribe();
      if (process.env.NODE_ENV === "development") {
        console.log(`UNSUBBING ${path}/${id}`);
      }
    };

    return unsubAndLog; // This returned function can be used to unsubscribe from updates
  }

  async count(path: string, whereQueries?: WhereQuery[]): Promise<number> {
    let q: Query<DocumentData> = collection(this.db, path);

    whereQueries?.forEach((whereQuery) => {
      q = query(q, where(whereQuery.key, whereQuery.filter, whereQuery.value));
    });
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }
}
