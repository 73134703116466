import React from "react";
import CheckFilter, { CheckFilterItem } from "../Notifications/CheckFilter";

interface CommonWorkflowPortalSwitchProps {
  useWorkflows: boolean;
  setUseWorkflows: (useWorkflows: boolean) => void;
}

export const CommonWorkflowPortalSwitch: React.FC<
  CommonWorkflowPortalSwitchProps
> = ({ useWorkflows, setUseWorkflows }) => {
  const items: CheckFilterItem[] = [
    {
      id: "portal",
      name: "Portals",
      selected: !useWorkflows,
    },
    {
      id: "workflow",
      name: "Workflows",
      selected: useWorkflows,
    },
  ];

  const handleUpdateItem = (item: CheckFilterItem) => {
    setUseWorkflows(item.id === "workflow");
  };

  return (
    <CheckFilter
      typeName="Type"
      typeNamePlural="Types"
      items={items}
      updatedItem={handleUpdateItem}
      leftIcon={useWorkflows ? "workflows" : "portals"}
      singleSelect={true}
    />
  );
};
