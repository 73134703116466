import React, { useCallback } from "react";
import { useStore, useStoreApi, Node, useReactFlow, Edge } from "reactflow";
import { PortalNodeData, PortalNodeType } from "./PortalNode";
import { WorkflowAddNodeEdgeType } from "../Edges/WorkflowAddNodeEdge";
import Icon, { IconType } from "../../../Icon";
import { DecisionNodeData, DecisionNodeType } from "./DecisionNode";
import { WebsiteNodeData, WebsiteNodeType } from "./WebsiteNode";
import { SearchNodeData, SearchNodeType } from "./SearchNode";
import { WorkflowNodeData, WorkflowNodeType } from "./WorkflowNode";
import { APINodeData, APINodeType } from "./APINode";
import { ActionNodeData, ActionNodeType } from "./ActionNode";
import { extractDefaultConfig } from "../../../../utils/ExtractDefaultChatConfig";
import { TemplatesContext } from "../../WorkflowDetail";

export const SelectorNodeType = "workflowNodeSelectorNode";

export const createSelectorNode = (
  source: Node<any>,
  teamId: string,
  targetId?: string,
  parentEdge?: string,
  handleId?: string
): Node<SelectorNodeData> => {
  const newSelectionNode: Node<SelectorNodeData> = {
    id: SelectorNodeType,
    type: SelectorNodeType,
    position: {
      x: source.position.x - 80,
      y: source.position.y + (source.height ?? 0) * 1.1,
    },
    data: { parentId: source.id, teamId, targetId, parentEdge, handleId },
    draggable: false,
    zIndex: 1000,
  };
  return newSelectionNode;
};

export interface SelectorNodeData {
  parentId: string;
  targetId?: string;
  teamId: string;
  parentEdge?: string;
  handleId?: string;
}

export interface SelectorNodeProps {
  data: SelectorNodeData;
  id: string;
}

type NodeType =
  | typeof PortalNodeType
  | typeof DecisionNodeType
  | typeof WebsiteNodeType
  | typeof SearchNodeType
  | typeof WorkflowNodeType
  | typeof APINodeType
  | typeof ActionNodeType;

type NodeData =
  | PortalNodeData
  | DecisionNodeData
  | WebsiteNodeData
  | SearchNodeData
  | WorkflowNodeData
  | APINodeData
  | ActionNodeData;

export const SelectorNode: React.FC<SelectorNodeProps> = ({ data }) => {
  const store = useStoreApi();
  const { setNodes, getNode, setEdges } = useReactFlow();

  const getZoom = useCallback(() => {
    const state = store.getState();
    return state.transform[2];
  }, [store]);

  const zoom = useStore(getZoom);

  const templates = React.useContext(TemplatesContext);

  const nodeData = (nodeType: NodeType): NodeData => {
    const prefix = `node_${nodeType}`;
    const newNodeId = `${prefix}${Math.random()}`;
    switch (nodeType) {
      case APINodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: APINodeType,
          title: "API Beam",
          method: "GET",
          url: "",
          headers: {},
        };
      case PortalNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: PortalNodeType,
          title: "Portal Beam",
          includeMessages: true,
        };
      case DecisionNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: DecisionNodeType,
          title: "Decision Beam",
          selectedOperator: "==",
        };
      case WebsiteNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: WebsiteNodeType,
          title: "Website Beam",
        };
      case SearchNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: SearchNodeType,
          title: "Search Beam",
        };
      case WorkflowNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: WorkflowNodeType,
          title: "Workflow Beam",
        };
      case ActionNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: ActionNodeType,
          title: "Action Beam",
          actions: [],
          providerId: "openai",
          providerConfig: extractDefaultConfig(
            templates.filter((t) => t.id == "openai")[0]
          ),
        };
    }
  };

  const createNewNode = (nodeType: NodeType, parent: Node): Node<NodeData> => {
    const newData = nodeData(nodeType);
    let xAdjustment = 0;
    if (data.handleId) {
      if (data.handleId.includes("_success")) {
        xAdjustment = -120;
      } else if (data.handleId.includes("_false")) {
        xAdjustment = 160;
      } else {
        let totalActionsCount = 0;
        if (parent.data.type === ActionNodeType) {
          totalActionsCount = parent.data.actions.length;
          const indexMatch = data.handleId?.match(/INDEX:(\d+)/);
          if (indexMatch) {
            const currentIndex = parseInt(indexMatch[1]);
            xAdjustment = (totalActionsCount - currentIndex) * 240;
          }
        }
      }
    }

    return {
      id: newData.id,
      type: nodeType,
      position: {
        x: parent.position.x + xAdjustment,
        y: parent.position.y + (parent.height ?? 0) + 180,
      },
      selected: true,
      data: newData,
    };
  };

  const handleCreate = (nodeType: NodeType) => {
    const parent = getNode(data.parentId);
    if (!parent) {
      console.error("No parent for selector node");
      return;
    }

    const newNode = createNewNode(nodeType, parent);

    if (data.targetId) {
      const targetNode = getNode(data.targetId);
      if (targetNode) {
        setNodes((nodes) => {
          const updatedNodes = nodes.map((node) => {
            if (
              node.id === data.targetId ||
              node.position.y > targetNode.position.y
            ) {
              return {
                ...node,
                position: {
                  ...node.position,
                  y: node.position.y + 300,
                },
              };
            }
            return node;
          });
          return [
            ...updatedNodes.filter((node) => node.id !== SelectorNodeType),
            newNode,
          ];
        });

        const newEdges: Edge[] = [
          {
            id: `edge_${parent.id}-${newNode.id}`,
            source: parent.id,
            sourceHandle: data.handleId,
            target: newNode.id,
            type: WorkflowAddNodeEdgeType,
          },
          {
            id: `edge_${newNode.id}-${data.targetId}`,
            source: newNode.id,
            target: data.targetId,
            type: WorkflowAddNodeEdgeType,
          },
        ];

        setEdges((edges) =>
          [...edges, ...newEdges].filter((edge) => edge.id !== data.parentEdge)
        );
      }
    } else {
      setNodes((nodes) => [
        ...nodes.filter((node) => node.id !== SelectorNodeType),
        newNode,
      ]);

      const isFromAction = data.handleId?.includes("INDEX") == true;

      const newEdge: Edge = {
        id: `edge_${parent.id}-${newNode.id}`,
        source: parent.id,
        sourceHandle: data.handleId,
        target: newNode.id,
        type: WorkflowAddNodeEdgeType,
        zIndex: isFromAction ? 2000 : undefined,
      };

      setEdges((edges) => [...edges, newEdge]);
    }
  };

  const requestFeature = (feature: string) => {
    if (window.Intercom) {
      window.Intercom("showNewMessage", `Feature request: ${feature} now plz`);
    } else {
      console.warn("Intercom is not available");
    }
  };

  return (
    <div
      style={{
        transform: `scale(${1 / zoom})`,
        transformOrigin: "0 0",
        position: "relative",
      }}
    >
      <div className="bg-white shadow-lg rounded-lg w-[400px] flex flex-col p-4 gap-2">
        <SelectionButton
          icon="portals"
          title="Portal"
          onClick={() => handleCreate(PortalNodeType)}
          className="text-lime-400 size-5"
          buttonClassName="hover:bg-lime-50"
        />
        <SelectionButton
          icon="api"
          title="Decision"
          onClick={() => handleCreate(DecisionNodeType)}
          className="text-orange-400 size-5"
          buttonClassName="hover:bg-orange-50"
        />
        <SelectionButton
          icon="arrow-up-down"
          title="API"
          onClick={() => handleCreate(APINodeType)}
          className="text-purple-400 size-5"
          buttonClassName="hover:bg-purple-50"
        />
        <SelectionButton
          icon="tools"
          title="Actions"
          onClick={() => handleCreate(ActionNodeType)}
          className="text-yellow-400 size-5"
          buttonClassName="hover:bg-yellow-50"
          comingSoon={true}
        />
        <SelectionButton
          icon="search"
          title="Search"
          onClick={() => requestFeature("search beam")}
          className="text-pink-400 size-5"
          buttonClassName="hover:bg-pink-50"
          comingSoon={true}
        />
        <SelectionButton
          icon="workflows"
          title="Workflow"
          onClick={() => requestFeature("workflow beam")}
          className="text-green-400 size-5"
          buttonClassName="hover:bg-green-50"
          comingSoon={true}
        />
      </div>
    </div>
  );
};

const SelectionButton: React.FC<{
  icon: IconType;
  title: string;
  className: string;
  onClick: () => void;
  buttonClassName: string;
  comingSoon?: boolean;
}> = ({ icon, title, onClick, className, buttonClassName, comingSoon }) => {
  return (
    <button
      onClick={onClick}
      className={`flex flex-row px-3 py-2 justify-between items-center rounded-lg bg-gray-50 ${buttonClassName}`}
    >
      <div className="flex flex-row gap-2 items-center">
        <Icon type={icon} className={className} />
        <div className="text-gray-500 font-medium text-sm">{title}</div>
      </div>
      <div className="flex flex-row justify-between items-center gap-1">
        {comingSoon == true && (
          <div className="h-[22px] px-1 py-0.5 bg-[#eaefff] rounded justify-start items-center gap-1 inline-flex">
            <div className="text-center text-[#3062ff] text-xs font-medium font-['General Sans'] leading-[18px]">
              Coming Soon
            </div>
          </div>
        )}

        <Icon type="chevron" className="text-gray-400 size-8 rotate-90" />
      </div>
    </button>
  );
};
