import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CheckFilter, { CheckFilterItem } from "../Notifications/CheckFilter";
import { Workflow, WorkflowVersion } from "../../models/Workflow";
import { Portal } from "../../models/Portal";
import { PortalVersion } from "../../models/PortalVersion";
import { usePortalService } from "../../contexts/PortalContext";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import {
  filterExamplePortals,
  filterExampleWorkflows,
} from "../../utils/FilterUtils";
import { Team } from "../../models/Team";
import { CommonWorkflowPortalSwitch } from "./CommonWorkflowPortalSwitch";

export type Timespan = "7-Days" | "30-Days" | "60-Days";

export type SelectionResult = WorkflowSelectionResult | PortalSelectionResult;

export interface WorkflowSelectionResult {
  type: "workflow";
  workflow: Workflow;
  versions: WorkflowVersion[];
  timespan?: Timespan;
}

export interface PortalSelectionResult {
  type: "portal";
  portal: Portal;
  versions: PortalVersion[];
  timespan?: Timespan;
}

interface CommonWorkflowPortalSelectorProps {
  showTimespan: boolean;
  team: Team;
}

export const CommonWorkflowPortalSelector: React.FC<
  CommonWorkflowPortalSelectorProps
> = ({ showTimespan, team }) => {
  const portalService = usePortalService();
  const workflowService = useWorkflowService();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingVersions, setLoadingVersions] = useState(false);

  // Get URL parameters
  const urlType = searchParams.get("type") as "workflow" | "portal" | null;
  const urlItemId = searchParams.get("id");
  const urlVersionIds =
    searchParams.get("versionIds")?.split(",").filter(Boolean) || [];
  const urlTimespan = searchParams.get("timespan") as Timespan | null;

  const [useWorkflows, setUseWorkflows] = useState(urlType === "workflow");
  const [mainFilterItems, setMainFilterItems] = useState<CheckFilterItem[]>([]);
  const [versionFilterItems, setVersionFilterItems] = useState<
    CheckFilterItem[]
  >([]);

  const timespans: Timespan[] = ["7-Days", "30-Days", "60-Days"];
  const [timespanFilterItems, setTimespanFilterItems] = useState<
    CheckFilterItem[]
  >(
    timespans.map((t) => ({
      id: t,
      name: t,
      selected: t === urlTimespan || (urlTimespan === null && t === "7-Days"),
    }))
  );

  // Handle workflow/portal toggle
  const handleToggleType = (useWorkflows: boolean) => {
    setUseWorkflows(useWorkflows);
    setSearchParams(
      (params) => {
        params.set("type", useWorkflows ? "workflow" : "portal");
        params.delete("id");
        params.delete("versionIds");
        return params;
      },
      { replace: true }
    );
  };

  // Fetch initial data
  useEffect(() => {
    if (!team || loadingMain) return;

    const loadMainItems = async () => {
      setLoadingMain(true);
      try {
        if (urlType === null) {
          setSearchParams(
            (params) => {
              params.set("type", "portal");
              return params;
            },
            { replace: true }
          );
        }

        if (useWorkflows) {
          const fetchedWorkflows = await workflowService.workflowRepo.getList(
            workflowService.workflowPath(team.id!),
            { name: "modifiedAt", descending: true }
          );

          const filteredWorkflows = fetchedWorkflows.filter((w) =>
            filterExampleWorkflows(team ?? undefined, w)
          );

          const mostRecentWorkflow = filteredWorkflows[0];

          setMainFilterItems(
            filteredWorkflows.map((w) => ({
              name: w.name,
              id: w.id!,
              selected: urlItemId
                ? w.id === urlItemId
                : w.id === mostRecentWorkflow?.id,
            }))
          );
        } else {
          const fetchedPortals = await portalService.portalRepo.getList(
            portalService.portalPath(team.id!),
            { name: "modifiedAt", descending: true }
          );

          const filteredPortals = fetchedPortals.filter((p) =>
            filterExamplePortals(team ?? undefined, p)
          );

          const mostRecentPortal = filteredPortals[0];

          setMainFilterItems(
            filteredPortals.map((p) => ({
              name: p.name,
              id: p.id,
              selected: urlItemId
                ? p.id === urlItemId
                : p.id === mostRecentPortal?.id,
            }))
          );
        }
      } catch (error) {
        console.error("Error loading main items:", error);
      }
      setLoadingMain(false);
    };

    loadMainItems();
  }, [team?.id, useWorkflows, urlItemId]);

  // Handle default selection for main items
  useEffect(() => {
    if (!mainFilterItems.length || loadingMain || urlItemId) return;

    const selectedItem = mainFilterItems.find((item) => item.selected);
    if (selectedItem) {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          newParams.set("id", selectedItem.id);
          return newParams;
        },
        { replace: true }
      );
    }
  }, [mainFilterItems.length, loadingMain, setSearchParams]);

  // Handle default selection for versions (this effect already exists)
  useEffect(() => {
    if (!versionFilterItems.length || loadingVersions || urlVersionIds.length)
      return;

    const selectedVersions = versionFilterItems.filter((item) => item.selected);
    if (selectedVersions.length) {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          newParams.set(
            "versionIds",
            selectedVersions.map((v) => v.id).join(",")
          );
          return newParams;
        },
        { replace: true }
      );
    }
  }, [versionFilterItems.length, loadingVersions, setSearchParams]);

  // Fetch versions
  useEffect(() => {
    if (!team?.id || !urlItemId || loadingVersions) return;

    const loadVersions = async () => {
      setLoadingVersions(true);
      try {
        if (useWorkflows) {
          const versions = await workflowService.workflowVersionRepo.getList(
            workflowService.workflowVersionPath(team.id!, urlItemId),
            { name: "modifiedAt", descending: true }
          );

          const mostRecentVersion = versions[0];

          setVersionFilterItems(
            versions.map((w) => ({
              name: w.name,
              id: w.id!,
              selected:
                urlVersionIds.length > 0
                  ? urlVersionIds.includes(w.id!)
                  : w.id === mostRecentVersion?.id,
            }))
          );
        } else {
          const versions = await portalService.portalVersionRepo.getList(
            portalService.portalVersionPath(team.id!, urlItemId),
            { name: "modifiedAt", descending: true }
          );

          const mostRecentVersion = versions[0];

          setVersionFilterItems(
            versions.map((p) => ({
              name: p.name,
              id: p.id!,
              selected:
                urlVersionIds.length > 0
                  ? urlVersionIds.includes(p.id!)
                  : p.id === mostRecentVersion?.id,
            }))
          );
        }
      } catch (error) {
        console.error("Error loading versions:", error);
      }
      setLoadingVersions(false);
    };

    loadVersions();
  }, [team?.id, urlItemId, useWorkflows]); // Removed urlVersionIds

  const handleSetTimeframe = (item: CheckFilterItem) => {
    const timespan = item.id as Timespan;
    setSearchParams(
      (params) => {
        params.set("timespan", timespan);
        return params;
      },
      { replace: true }
    );

    timespanFilterItems.forEach((i) => (i.selected = i.id === timespan));
    setTimespanFilterItems([...timespanFilterItems]);
  };

  const handleUpdateMainItem = (item: CheckFilterItem) => {
    setSearchParams(
      (params) => {
        params.set("id", item.id);
        params.delete("versionIds");
        return params;
      },
      { replace: true }
    );

    mainFilterItems.forEach((i) => (i.selected = i.id === item.id));
    setMainFilterItems([...mainFilterItems]);
  };

  const handleUpdateVersionItem = (item: CheckFilterItem) => {
    const newSelection = !item.selected;
    const updatedItems = versionFilterItems.map((i) =>
      i.id === item.id ? { ...i, selected: newSelection } : i
    );

    const selectedVersionIds = updatedItems
      .filter((i) => i.selected)
      .map((i) => i.id)
      .join(",");

    setSearchParams(
      (params) => {
        if (selectedVersionIds) {
          params.set("versionIds", selectedVersionIds);
        } else {
          params.delete("versionIds");
        }
        return params;
      },
      { replace: true }
    );

    setVersionFilterItems(updatedItems);
  };

  return (
    <div
      className={`grid ${
        showTimespan ? "grid-cols-4" : "grid-cols-3"
      } gap-4 pt-2`}
    >
      <CommonWorkflowPortalSwitch
        useWorkflows={useWorkflows}
        setUseWorkflows={handleToggleType}
      />
      <CheckFilter
        typeName={useWorkflows ? "Workflows" : "All Portals"}
        typeNamePlural={useWorkflows ? "All Workflows" : "All Portals"}
        items={mainFilterItems}
        updatedItem={handleUpdateMainItem}
        leftIcon={useWorkflows ? "workflows" : "portals"}
        singleSelect={true}
        loading={loadingMain}
      />
      <CheckFilter
        typeName="Version"
        typeNamePlural="All Versions"
        items={versionFilterItems}
        updatedItem={handleUpdateVersionItem}
        leftIcon="versions"
        loading={loadingVersions}
      />
      {showTimespan && (
        <CheckFilter
          typeName="Date Range"
          typeNamePlural="Dates"
          items={timespanFilterItems}
          updatedItem={handleSetTimeframe}
          leftIcon="clock"
        />
      )}
    </div>
  );
};
