import React, { useState } from "react";
import TeamOnboardingPage from "./TeamOnboardingPage";
import ClearableInput from "../Onboarding/ClearableInput";
import { TeamOnboardingStep } from "./TeamOnboarding";
import { NextButton } from "../Onboarding/NextButton";
import Icon from "../Icon";
import { motion } from "framer-motion";

const TeamAddName: React.FC<{
  gotName: (name: string) => void;
  progress: number;
  setStep: (step: TeamOnboardingStep) => void;
  name: string;
  setName: (teamName: string) => void;
  goBack?: () => void;
}> = ({ gotName, progress, setStep, setName, name, goBack }) => {
  const [nameError, setNameError] = useState("");

  const handleSubmit = async () => {
    if (name.length < 4) {
      setNameError("Please add a name that's longer than 4 chars");
      return;
    }

    gotName(name);
    setStep(TeamOnboardingStep.Subscriptions);
  };

  const onUnfocus = () => {
    if (name.length < 4) {
      setNameError("Please add a name that's longer than 4 chars");
    }
  };

  const setAndResetError = (text: string) => {
    setNameError("");
    setName(text);
  };

  return (
    <TeamOnboardingPage
      error={""}
      title={"Let's make your first team"}
      headline="What's your team called?"
      message="This is what your team members will see when invited"
      isLoading={false}
      progress={progress}
      setError={() => undefined}
    >
      <div className="w-96 items-center">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            duration: 0.3,
          }}
        >
          <ClearableInput
            text={name}
            setText={setAndResetError}
            error={nameError}
            placeholder="Name goes here"
            onUnfocus={onUnfocus}
          />
        </motion.div>
      </div>
      <div className="flex flex-row gap-2">
        {goBack && (
          <button
            type="button"
            onClick={() => goBack()}
            className="text-gray-400 gap-1 bg-gray-100 hover:bg-gray-200  font-medium rounded-full px-4 py-3 text-center inline-flex items-center "
          >
            <Icon type="arrow-left" />
            Exit
          </button>
        )}
        <NextButton onClick={handleSubmit} />
      </div>
    </TeamOnboardingPage>
  );
};

export default TeamAddName;
