import React, { useEffect, useRef, useState } from "react";
import { PortalTest } from "../../models/PortalTest";
import { WorkflowTest } from "../../models/WorkflowTest";
import Icon from "../Icon";
import CommonPagination from "./Pagination";
import { CommonSpinner } from "../Common/CommonLoading";
import { InputData } from "./TestRunDetailModal";
import { useTestService } from "../../contexts/TestContext";
import { TestDetailModal } from "./TestDetailModal";
import { Team } from "../../models/Team";
import { useSearchParams } from "react-router-dom";

type Test = PortalTest | WorkflowTest;

export const TestTable: React.FC<{
  data: InputData | undefined;
  onSelectedTests: (selected: string[]) => void;
  team: Team | undefined;
}> = ({ data, onSelectedTests, team }) => {
  const [tests, setTests] = useState<Test[]>();
  const [loading, setLoading] = useState(false);
  const [currentTestPage, setCurrentTestPage] = useState(1);
  const unsubTestRef = useRef<() => void>();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageLimit = 10;
  const testService = useTestService();
  const [testPageDates, setTestPageDates] = useState<Date[]>([]);
  const [selectedTests, setSelectedTests] = useState<string[]>([]);

  // Get appropriate test ID based on type
  const portalTestId = searchParams.get("portalTestId");
  const workflowTestId = searchParams.get("workflowTestId");
  const currentTestId = data?.type === "portal" ? portalTestId : workflowTestId;

  useEffect(() => {
    const portalId = data?.type === "portal" ? data.itemId : undefined;
    const workflowId = data?.type === "workflow" ? data.itemId : undefined;

    const fetchTests = async () => {
      if ((!portalId && !workflowId) || loading || tests !== undefined) return;

      setLoading(true);
      try {
        const startAfterDate =
          currentTestPage === 1
            ? undefined
            : testPageDates[currentTestPage - 1];

        unsubTestRef.current?.();

        if (data?.type === "portal" && portalId) {
          unsubTestRef.current = testService.testRepo.observeList(
            testService.testPath(team?.id!, portalId),
            async (tests) => {
              setTests(tests);
              if (tests.length > 0) {
                const lastTestDate = tests[tests.length - 1].createdAt!;
                setTestPageDates((prevDates) => {
                  const newDates = [...prevDates];
                  newDates[currentTestPage] = lastTestDate;
                  return newDates;
                });
              }
            },
            { name: "createdAt", descending: true },
            pageLimit,
            startAfterDate
          );
        } else if (data?.type === "workflow" && workflowId) {
          unsubTestRef.current = testService.workflowTestRepo.observeList(
            testService.workflowTestPath(team?.id!, workflowId),
            async (tests) => {
              setTests(tests);
              if (tests.length > 0) {
                const lastTestDate = tests[tests.length - 1].createdAt!;
                setTestPageDates((prevDates) => {
                  const newDates = [...prevDates];
                  newDates[currentTestPage] = lastTestDate;
                  return newDates;
                });
              }
            },
            { name: "createdAt", descending: true },
            pageLimit,
            startAfterDate
          );
        }
      } catch (e) {
        setTests([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTests();
  }, [data, testService, currentTestPage, testPageDates, team?.id]);

  useEffect(() => {
    setTests(undefined);
    setSelectedTests([]);
    setCurrentTestPage(1);
    setTestPageDates([]);
  }, [data?.type, data?.itemId]);

  const handleTestClick = (test: Test) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (data?.type === "portal") {
      newSearchParams.set("portalTestId", test.id!);
    } else {
      newSearchParams.set("workflowTestId", test.id!);
    }
    setSearchParams(newSearchParams);
  };

  const handleCloseModal = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (data?.type === "portal") {
      newSearchParams.delete("portalTestId");
    } else {
      newSearchParams.delete("workflowTestId");
    }
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex flex-col gap-6">
      {currentTestId && team && data && (
        <TestDetailModal
          testId={currentTestId}
          data={data}
          closed={handleCloseModal}
          team={team}
        />
      )}

      {loading || data == undefined ? (
        <div className="flex items-center justify-center">
          <CommonSpinner />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="font-gooper text-xl text-gray-700">
              Test Selection
            </div>
            <div className="text-sm text-gray-500">
              Select which test you want to run, or click the looking glass to
              edit.
            </div>
          </div>
          <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
            <tbody>
              {tests?.map((test) => {
                return (
                  <tr
                    className="bg-gray-0 border border-gray-200"
                    key={test.id}
                  >
                    <td className="w-4 p-3">
                      <div className="flex flex-row gap-4 w-full justify-between items-center">
                        <input
                          type="checkbox"
                          checked={selectedTests.includes(test.id ?? "")}
                          onChange={() => {
                            if (selectedTests.includes(test.id ?? "")) {
                              const newTests = selectedTests.filter(
                                (t) => t != test.id
                              );
                              setSelectedTests(newTests);
                              onSelectedTests(newTests);
                            } else {
                              const newTests = [
                                ...selectedTests,
                                test.id ?? "",
                              ];
                              setSelectedTests(newTests);
                              onSelectedTests(newTests);
                            }
                          }}
                          className="size-4 bg-gray-100 border border-gray-300 rounded checked:bg-blue-500"
                          id={`${test.id}Button`}
                        />
                        <div className="w-full text-gray-700 text-sm font-medium">
                          {test.name}
                        </div>
                        <button
                          onClick={() => handleTestClick(test)}
                          className="bg-blue-50 rounded-full p-1.5 hover:bg-blue-100"
                        >
                          <Icon
                            type="search"
                            className="size-4 text-blue-500"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {tests?.length == 0 && !loading && (
        <div className="text-gray-700 font-gooper">
          Create a test with the button on the top right.
        </div>
      )}
      <CommonPagination
        currentPage={currentTestPage}
        hasMore={(tests?.length ?? 0) > pageLimit}
        onPageChange={(direction) => {
          setTests(undefined);
          if (direction === "next") {
            setCurrentTestPage((prev) => prev + 1);
          } else if (direction === "prev" && currentTestPage > 1) {
            setCurrentTestPage((prev) => prev - 1);
          }
        }}
      />
    </div>
  );
};
