import { WorkflowDemoActionResult } from "../../../../../models/WorkflowDemoResult";
import { formatDate, formatTime } from "../../../../../utils/DateUtils";
import Icon from "../../../../Icon";

interface ActionResultModalProps {
  result: WorkflowDemoActionResult;
  shows: boolean;
  onClose: () => void;
}

export const ActionResultModal: React.FC<ActionResultModalProps> = ({
  result,
  shows,
  onClose,
}) => {
  const statusColor = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "bg-green-50 text-green-400";
      case "ERROR":
        return "bg-yellow-50 text-yellow-400";
      case "IN_PROGRESS":
        return "bg-red-50 text-red-400";
      default:
        return "";
    }
  };

  const statusTitle = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "success";
      case "ERROR":
        return "error";
      case "IN_PROGRESS":
        return "running";
      default:
        return "";
    }
  };

  if (!shows) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div className="bg-white rounded-lg flex flex-col shadow-lg w-[544px] relative overflow-y-auto">
        <div className="flex justify-end">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={onClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
          <div className="flex justify-between items-center">
            <div className="flex justify-between text-xs text-gray-500 gap-2">
              <span>Created</span>
              <span>{formatDate(result.createdAt)}</span>
              <span>{formatTime(result.createdAt)}</span>
            </div>
            <div
              className={`text-xs font-medium px-2 py-1 rounded ${statusColor()}`}
            >
              {statusTitle()}
            </div>
          </div>

          <div className="flex flex-col gap-1 justify-center items-center">
            <div className="text-gray-500 text-xs">Action Result</div>
            <span className="text-gray-900 text-2xl font-gooper">
              {result.name}
            </span>
          </div>

          <ActionBlock
            actionsCount={result.actionsCount}
            selectedActionName={result.selectedActionName}
            error={result.error}
          />
        </div>
      </div>
    </div>
  );
};

const ActionBlock: React.FC<{
  actionsCount: number;
  selectedActionName?: string;
  error?: string;
}> = ({ actionsCount, selectedActionName, error }) => {
  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex-col gap-2 inline-flex">
      <div className="flex items-center gap-2 p-3 border-b border-gray-200">
        <Icon type="tools" className="text-yellow-500 size-4" />
        <div className="font-gooper text-gray-700 text-sm">
          Action Selection
        </div>
      </div>
      <div className="p-3 flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-600">
            Total Actions
          </span>
          <span className="text-sm text-gray-700">{actionsCount}</span>
        </div>

        {selectedActionName && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-600">
              Selected Action
            </span>
            <div className="text-sm text-gray-700">{selectedActionName}</div>
          </div>
        )}

        {error && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-red-500">Error</span>
            <div className="text-sm text-red-700">{error}</div>
          </div>
        )}
      </div>
    </div>
  );
};
