import React, { useState } from "react";
import { WorkflowDemoAPIResult } from "../../../../../models/WorkflowDemoResult";
import { StatusBadge } from "../../../../TestCenter/StatusBadge";
import { APIResultModal } from "./APIResultModal";
import { APIBadge } from "../../../Map/Nodes/APINode";

export const APIResultCard: React.FC<{
  result: WorkflowDemoAPIResult;
}> = ({ result }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      onClick={() => setShowModal(true)}
      key={result.id}
      className="h-24 shadow-sm cursor-pointer hover:shadow-md flex flex-col rounded-lg bg-gray-50 p-2 gap-2 border border-gray-200 transition-all"
    >
      <APIResultModal
        result={result}
        shows={showModal}
        onClose={() => setShowModal(false)}
      />
      <div className="flex flex-row justify-between">
        <APIBadge method={result.method} />
        <StatusBadge status={result.status} />
      </div>
      <div className="text-2xl font-gooper text-gray-900">{result.name}</div>
    </div>
  );
};
