export const TableHeader: React.FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  return (
    <th
      className={`p-3 text-left bg-gray-50 p4 text-xs font-medium text-gray-500 uppercase tracking-widest ${className}`}
    >
      {title}
    </th>
  );
};
