import { WorkflowDemoAPIResult } from "../../../../../models/WorkflowDemoResult";
import { formatDate, formatTime } from "../../../../../utils/DateUtils";
import Icon from "../../../../Icon";

interface APIResultModalProps {
  result: WorkflowDemoAPIResult;
  shows: boolean;
  onClose: () => void;
}

export const APIResultModal: React.FC<APIResultModalProps> = ({
  result,
  shows,
  onClose,
}) => {
  const statusColor = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "bg-green-50 text-green-400";
      case "ERROR":
        return "bg-yellow-50 text-yellow-400";
      case "IN_PROGRESS":
        return "bg-red-50 text-red-400";
      default:
        return "";
    }
  };

  const statusTitle = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "success";
      case "ERROR":
        return "error";
      case "IN_PROGRESS":
        return "running";
      default:
        return "";
    }
  };

  if (!shows) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div className="bg-white rounded-lg flex flex-col shadow-lg w-[544px] relative overflow-y-auto">
        <div className="flex justify-end">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={onClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
          <div className="flex justify-between items-center">
            <div className="flex justify-between text-xs text-gray-500 gap-2">
              <span>Created</span>
              <span>{formatDate(result.createdAt)}</span>
              <span>{formatTime(result.createdAt)}</span>
            </div>
            <div
              className={`text-xs font-medium px-2 py-1 rounded ${statusColor()}`}
            >
              {statusTitle()}
            </div>
          </div>

          <div className="flex flex-col gap-1 justify-center items-center">
            <div className="text-gray-500 text-xs">API Result</div>
            <span className="text-gray-900 text-2xl font-gooper">
              {result.name}
            </span>
          </div>

          <RequestBlock
            method={result.method}
            url={result.url}
            headers={result.headers ?? {}}
            body={result.body}
          />

          <ResponseBlock
            status={result.responseStatus}
            body={result.responseBody}
            outputs={result.mappedOutputs}
            error={result.error}
          />
        </div>
      </div>
    </div>
  );
};

const RequestBlock: React.FC<{
  method: string;
  url: string;
  headers: Record<string, string>;
  body?: string;
}> = ({ method, url, headers, body }) => {
  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex-col gap-2 inline-flex">
      <div className="flex items-center gap-2 p-3 border-b border-gray-200">
        <Icon type="api" className="text-blue-500 size-4" />
        <div className="font-gooper text-gray-700 text-sm">Request</div>
      </div>
      <div className="p-3 flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-600">{method}</span>
          <span className="text-sm text-gray-700 font-mono break-all">
            {url}
          </span>
        </div>
        {Object.keys(headers).length > 0 && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-600">Headers</span>
            <div className="font-mono text-sm text-gray-700">
              {Object.entries(headers).map(([key, value]) => (
                <div key={key}>
                  {key}: {value}
                </div>
              ))}
            </div>
          </div>
        )}
        {body && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-600">Body</span>
            <div className="font-mono text-sm text-gray-700 whitespace-pre-wrap">
              {body}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ResponseBlock: React.FC<{
  status?: number;
  body?: string;
  outputs?: Record<string, string>;
  error?: string;
}> = ({ status, body, outputs, error }) => {
  const statusColor =
    status && status >= 200 && status < 300 ? "text-green-500" : "text-red-500";

  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex-col gap-2 inline-flex">
      <div className="flex items-center gap-2 p-3 border-b border-gray-200">
        <Icon type="reply-all" className="text-blue-500 size-4" />
        <div className="font-gooper text-gray-700 text-sm">Response</div>
      </div>
      <div className="p-3 flex flex-col gap-3">
        {status && (
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium text-gray-600">Status</span>
            <span className={`text-sm font-medium ${statusColor}`}>
              {status}
            </span>
          </div>
        )}
        {error && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-red-500">Error</span>
            <div className="text-sm text-red-700">{error}</div>
          </div>
        )}
        {body && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-600">Body</span>
            <div className="font-mono text-sm text-gray-700 whitespace-pre-wrap">
              {body}
            </div>
          </div>
        )}
        {outputs && Object.keys(outputs).length > 0 && (
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium text-gray-600">
              Mapped Outputs
            </span>
            <div className="font-mono text-sm text-gray-700">
              {Object.entries(outputs).map(([key, value]) => (
                <div key={key}>
                  {key}: {value}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
