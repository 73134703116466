import { useState } from "react";
import { useReactFlow } from "reactflow";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { CommonInputSegment } from "../../../Common/CommonInput";
import Icon from "../../../Icon";
import { ActionNodeData } from "../../Map/Nodes/ActionNode";
import { AIProviderList } from "../../../Portals/Portal Detail/AIProvider/AIProviderList";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";
import { extractDefaultConfig } from "../../../../utils/ExtractDefaultChatConfig";

export const SidebarActionContainer: React.FC<{
  team: Team;
  data: ActionNodeData;
  version: WorkflowVersion;
  templates: ChatConfigurationTemplate[];
}> = ({ team, data, version, templates }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<ActionNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  return (
    <div className="flex flex-col gap-2 -mt-2">
      <SidebarActionDetail
        data={data}
        updateNode={updateNode}
        team={team}
        version={version}
        templates={templates}
      />
    </div>
  );
};

export const SidebarActionDetail: React.FC<{
  data: ActionNodeData;
  updateNode: (newData: Partial<ActionNodeData>) => void;
  team: Team;
  version: WorkflowVersion;
  templates: ChatConfigurationTemplate[];
}> = ({ data, updateNode, templates, team }) => {
  const [_, setEditingActionId] = useState<string | null>(null);

  const addNewAction = () => {
    const newAction = {
      id: `action_${Date.now()}`,
      name: "New Action",
      description: "Describe this action...",
    };

    updateNode({
      actions: [...data.actions, newAction],
    });
    setEditingActionId(newAction.id);
  };

  const updateAction = (
    actionId: string,
    updates: Partial<{ name: string; description: string }>
  ) => {
    updateNode({
      actions: data.actions.map((action) =>
        action.id === actionId ? { ...action, ...updates } : action
      ),
    });
  };

  const deleteAction = (actionId: string) => {
    updateNode({
      actions: data.actions.filter((action) => action.id !== actionId),
    });
  };

  const handleSelectedProvider = (providerId: string) => {
    const template = templates.filter((t) => t.id == providerId)[0];
    const defaultConfig = extractDefaultConfig(template);
    updateNode({
      providerId: providerId,
      providerConfig: defaultConfig,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <CommonInputSegment
        title="Action Beam Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Beam Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />

      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between items-center">
          <div className="text-sm font-medium text-gray-700">Actions</div>
          <button
            onClick={addNewAction}
            className="p-2 text-blue-500 hover:bg-blue-50 rounded-md"
          >
            <Icon type="plus" className="size-6" />
          </button>
        </div>

        <AIProviderList
          clickedProvider={handleSelectedProvider}
          selectedProvider={data.providerId ?? ""}
          providers={templates}
          showProviderConfig={() => console.log("")}
          configuredProviders={Object.keys(team.secretsUsed ?? {})}
          hideConfigure={true}
        />
        {data.actions.map((action) => (
          <div
            key={action.id}
            className="flex flex-col gap-2 p-4 border border-gray-200 rounded-lg"
          >
            <div className="flex justify-between items-start">
              <div className="flex flex-col gap-2 flex-grow">
                <CommonInputSegment
                  title="Action Name"
                  onChange={(name) => updateAction(action.id, { name })}
                  value={action.name}
                  placeholder="Name this action"
                  error={undefined}
                  setError={undefined}
                  id={`action_name_${action.id}`}
                  className="font-sans text-gray-700"
                />
                <CommonInputSegment
                  title="Description"
                  onChange={(description) =>
                    updateAction(action.id, { description })
                  }
                  value={action.description}
                  placeholder="Describe what this action does"
                  error={undefined}
                  setError={undefined}
                  id={`action_description_${action.id}`}
                  className="font-sans text-gray-700"
                />
              </div>
              <button
                onClick={() => deleteAction(action.id)}
                className="ml-2 p-2 text-red-200 hover:bg-red-50 rounded-md"
              >
                <Icon type="x-no-circle" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
